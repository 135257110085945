import type {
  AccessoryProduct,
  DeviceProduct,
  InventoryInformation,
  MembershipProduct,
} from 'services/generated/growth-content-service';
import type { EngravingInfo } from '../utils/engravingUtils';

export type {
  CartInformation,
  InventoryInformation,
  JoinProducts,
  TryProducts,
  VariantInformation,
} from 'services/generated/growth-content-service';

export type {
  AccessoryProduct,
  DeviceProduct,
  MembershipProduct,
} from 'services/generated/growth-content-service';

export type AccessoryType = AccessoryProduct['accessory_type'];

export type DeviceType = DeviceProduct['device_type'];

export type MembershipType = MembershipProduct['membership_type'];

export enum ProductType {
  ACCESSORY = 'accessory',
  DEVICE = 'device',
  MEMBERSHIP = 'membership',
}

interface WhoopYourWayCartInformation {
  image?: string;
  images: string[];
  name: string;
  description?: string;
  descriptions: string[];
}

export interface WhoopYourWayProduct {
  id: string;
  accessory_type: AccessoryType;
  product_type: ProductType;
  display_price: number;
  subtotal: number;
  tax?: number;
  inventory_information: InventoryInformation;
  cart_information: WhoopYourWayCartInformation;
}

interface EngravingCartInformation {
  image: string;
  name: string;
  description?: string;
  descriptions: string[];
}

export interface EngravingProduct {
  id: string;
  accessory_type: AccessoryType;
  product_type: ProductType;
  display_price: number;
  subtotal: number;
  tax?: number;
  inventory_information?: InventoryInformation;
  cart_information: EngravingCartInformation;
  engraving: EngravingInfo;
}

interface PackCartInformation {
  image: string;
  name: string;
  description?: string;
  descriptions: string[];
}

export interface PackProduct {
  id: string;
  accessory_type: AccessoryType;
  product_type: ProductType;
  display_price: number;
  subtotal: number;
  tax?: number;
  inventory_information?: InventoryInformation;
  cart_information: PackCartInformation;
  pack_item_ids: string[];
  percent_discount?: number | null;
}

export type AnyProduct =
  | AccessoryProduct
  | DeviceProduct
  | MembershipProduct
  | WhoopYourWayProduct
  | EngravingProduct
  | PackProduct;

export type CartItemType = AnyProduct & {
  // Adding this to fix type errors because percent_discount, accessory_type, and pack_item_ids exist on only certain AnyProducts
  percent_discount?: number | null;
  accessory_type?: AccessoryType;
  membership_type?: MembershipType;
  family_size?: number;
};

export interface CartProduct {
  item: CartItemType;
  quantity: number;
}
