import {
  DEFAULT_CURRENCY,
  DEFAULT_LANGUAGE,
  DEFAULT_REGION,
  type Language,
  type Region,
  type JFCurrency,
} from '@whoop/i18n/types/internationalization';
import type { CountryCode } from '@whoop/i18n/types/address';
import { DEFAULT_COUNTRY_CODE } from '@whoop/i18n/types/address';
import type { StoreApi } from 'zustand';
import { createStore } from 'zustand';
import type {
  Address,
  AnyProduct,
  CartProduct,
  CreatedOrder,
  JoinProducts,
  OrderTotal,
  PlacedOrder,
  ProductNode,
  PromoCodeState,
  PromoInfo,
  ShippingOption,
  WhoopYourWayConfigurations,
} from 'ui';
import type {
  AccessoryGroup,
  JoinFlowContent,
} from 'services/generated/growth-content-service';
import type { CheckoutPromo } from 'services/generated/membership-service-v2';
import { immer } from 'zustand/middleware/immer';
import type { GiftMessage } from 'services/generated/order-service';
import type { AddressVerificationStatus } from 'ui/types/Address';
import type { StripePaymentRequest } from 'ui/types/Stripe';
import type { PromoType } from '@/lib/utils/initialDataFetcher';
import {
  addCartProduct,
  removeCartProduct,
  updateCartProductQuantity,
} from '../lib/utils/cartManager';
import type { SiteWidePromoContent } from '../types/siteWidePromoTypes';
import { adjustToJFCurrency } from '../lib/utils/currencyHelper';

interface ISiteProps {
  requiredProducts: JoinProducts;
  accessories: AccessoryGroup[] | undefined;
  asAGift: boolean;
  language: Language;
  region: Region;
  country: CountryCode;
  currency: JFCurrency;
  cartProducts: CartProduct[];
  cartExceedsBatteryLimit: boolean;
  selectedShipping?: ShippingOption;
  shippingAddress?: Address;
  pendingOrder?: CreatedOrder;
  placedOrder?: PlacedOrder;
  productNodes: Record<string, ProductNode>;
  initialTotals: OrderTotal[];
  promoInfo?: PromoInfo;
  promoCode?: PromoCodeState;
  whoopYourWayConfigs?: WhoopYourWayConfigurations;
  email?: string;
  isCartOpen: boolean;
  giftMessaging?: GiftMessage;
  // Once fully implemented, remove promoInfo and promoCode and replace everywhere in app with promoContent usage
  // Then this object should no longer be optional
  promoContent?: JoinFlowContent;
  featureFlags: Record<string, boolean>;
  experimentVariants: Record<string, string>;
  returnShippingCost?: number;
  siteWidePromoContent?: SiteWidePromoContent;
  isRafGiftFlow?: boolean;
  // non promo site wide banner text
  siteBannerText?: string;
  promoType: PromoType;
  stripePaymentRequest?: StripePaymentRequest;
  addressVerificationStatus: AddressVerificationStatus;
}

// Site Actions
interface ISiteActions {
  toggleAsAGift: () => void;
  setAsAGift: (isGift: boolean) => void;
  addToCart: (
    product: AnyProduct,
    quantity?: number,
    updateQuantity?: boolean,
  ) => void;
  removeFromCart: (product: CartProduct) => void;
  updateProductQuantity: (product: CartProduct, newQuantity: number) => void;
  setSelectedShipping: (option: ShippingOption | undefined) => void;
  initializeFeatureFlags: (featureFlags: Record<string, boolean>) => void;
  initializePromo: (
    promoContent?: JoinFlowContent,
    promo?: CheckoutPromo,
    siteWidePromoContent?: SiteWidePromoContent,
    isRafGiftFlow?: boolean,
  ) => void;
  setShippingAddress: (address: Address) => void;
  setPendingOrder: (order: CreatedOrder | undefined) => void;
  setPlacedOrder: (order: PlacedOrder) => void;
  setCartProducts: (cart: CartProduct[]) => void;
  setCartExceedsBatteryLimit: (boolean) => void;
  setPromoCode: (promoCode: PromoCodeState) => void;
  setEmail: (email: string | undefined) => void;
  setRequiredProducts: (requiredProducts: JoinProducts) => void;
  setAccessories: (accessories: AccessoryGroup[]) => void;
  setInitialTotals: (initialTotals: OrderTotal[]) => void;
  setProductNodes: (productNodes: Record<string, ProductNode>) => void;
  setWhoopYourWayConfigs: (wywConfigs?: WhoopYourWayConfigurations) => void;
  setReturnShippingCost: (returnShippingCost?: number) => void;
  toggleIsCartOpen: () => void;
  setIsCartOpen: (isCartOpen: boolean) => void;
  setGiftMessaging: (giftMessage: GiftMessage | undefined) => void;
  setExperimentVariant: (experimentName: string, variant: string) => void;
  setIsRafGiftFlow: (isRafGiftFlow: boolean) => void;
  // non promo site wide banner text
  setSiteBannerText: (bannerText: string | undefined) => void;
  setPromoType: (promoType: PromoType) => void;
  setStripePaymentRequest: (stripePaymentRequest: StripePaymentRequest) => void;
  setAddressVerificationStatus: (status: AddressVerificationStatus) => void;
}

export interface ISiteStore extends ISiteProps, ISiteActions {}

const DEFAULT_REQUIRED_PRODUCTS = {
  devices: [],
  join_memberships: [],
  gift_memberships: [],
};

export const DEFAULT_PROPS: ISiteProps = {
  requiredProducts: DEFAULT_REQUIRED_PRODUCTS,
  accessories: undefined,
  asAGift: false,
  language: DEFAULT_LANGUAGE,
  region: DEFAULT_REGION,
  country: DEFAULT_COUNTRY_CODE,
  currency: adjustToJFCurrency(DEFAULT_CURRENCY),
  cartProducts: [],
  cartExceedsBatteryLimit: false,
  initialTotals: [],
  isCartOpen: false,
  featureFlags: {},
  productNodes: {},
  experimentVariants: {},
  siteBannerText: undefined,
  promoType: 'None',
  stripePaymentRequest: undefined,
  addressVerificationStatus: 'Default',
};

export const createSiteStore = (
  initState: ISiteProps = DEFAULT_PROPS,
): StoreApi<ISiteStore> => {
  return createStore<ISiteStore>()(
    immer((set) => ({
      ...initState,
      toggleAsAGift: () =>
        set((state) => ({
          asAGift: !state.asAGift,
        })),
      setAsAGift: (isGift: boolean) =>
        set(() => ({
          asAGift: isGift,
        })),
      addToCart: (
        product: AnyProduct,
        quantity?: number,
        updateQuantity?: boolean,
      ) =>
        set((state) => {
          const newProduct = { item: product, quantity: quantity ?? 1 };
          state.cartProducts = addCartProduct(
            state.requiredProducts,
            state.cartProducts,
            newProduct,
            updateQuantity,
          );
        }),
      removeFromCart: (product: CartProduct) =>
        set((state) => {
          state.cartProducts = removeCartProduct(state.cartProducts, product);
        }),
      updateProductQuantity: (product: CartProduct, newQuantity: number) =>
        set((state) => {
          state.cartProducts = updateCartProductQuantity(
            state.cartProducts,
            product,
            newQuantity,
          );
          state.pendingOrder = undefined;
          state.selectedShipping = undefined;
        }),
      setRequiredProducts: (requiredProducts: JoinProducts) => {
        set((state) => {
          state.requiredProducts = requiredProducts;
        });
      },
      setAccessories: (accessories: AccessoryGroup[]) => {
        set((state) => {
          state.accessories = accessories;
        });
      },
      setInitialTotals: (initialTotals: OrderTotal[]) => {
        set((state) => {
          state.initialTotals = initialTotals;
        });
      },
      setProductNodes: (productNodes: Record<string, ProductNode>) => {
        set((state) => {
          state.productNodes = productNodes;
        });
      },
      setWhoopYourWayConfigs: (wywConfigs?: WhoopYourWayConfigurations) => {
        set((state) => {
          state.whoopYourWayConfigs = wywConfigs;
        });
      },
      setReturnShippingCost: (returnShippingCost?: number) => {
        set((state) => {
          state.returnShippingCost = returnShippingCost;
        });
      },
      initializeFeatureFlags: (featureFlags: Record<string, boolean>) => {
        set((state) => {
          state.featureFlags = featureFlags;
        });
      },
      initializePromo: (
        promoContent?: JoinFlowContent,
        promo?: CheckoutPromo,
        siteWidePromoContent?: SiteWidePromoContent,
        isRafGiftFlow?: boolean,
      ) => {
        set((state) => {
          // Future TODO : use this promo code everywhere instead of always doing this logic
          // https://whoopinc.atlassian.net/browse/GRO-3001
          state.promoCode =
            promoContent?.partnership_name || isRafGiftFlow
              ? promoContent?.promo_info
              : promo?.promo_code;
          state.promoContent = promoContent;
          state.promoInfo = promo;
          state.siteWidePromoContent = siteWidePromoContent;
        });
      },
      setSelectedShipping: (option: ShippingOption | undefined) => {
        set((state) => {
          state.selectedShipping = option;
        });
      },
      setShippingAddress: (address: Address) => {
        set((state) => {
          state.shippingAddress = address;
        });
      },
      setPendingOrder: (order: CreatedOrder | undefined) => {
        set((state) => {
          state.pendingOrder = order;
        });
      },
      setPlacedOrder: (order: PlacedOrder) => {
        set((state) => {
          state.placedOrder = order;
        });
      },
      setCartProducts: (newCart: CartProduct[]) =>
        set((state) => {
          state.cartProducts = newCart;
        }),
      setCartExceedsBatteryLimit: (hascartExceedsBatteryLimit: boolean) =>
        set((state) => {
          state.cartExceedsBatteryLimit = hascartExceedsBatteryLimit;
        }),
      setPromoCode: (promoCode: PromoCodeState) => {
        set((state) => {
          state.promoCode = promoCode;
        });
      },
      setEmail: (email: string | undefined) => {
        set((state) => {
          state.email = email;
        });
      },
      toggleIsCartOpen: () =>
        set((state) => {
          state.isCartOpen = !state.isCartOpen;
        }),
      setIsCartOpen: (isCartOpen: boolean) => {
        set((state) => {
          state.isCartOpen = isCartOpen;
        });
      },
      setGiftMessaging: (giftMessage: GiftMessage | undefined) => {
        set((state) => {
          state.giftMessaging = giftMessage;
        });
      },
      setExperimentVariant: (experimentName: string, variant: string) => {
        set((state) => {
          state.experimentVariants = {
            ...state.experimentVariants,
            [experimentName]: variant,
          };
        });
      },
      setIsRafGiftFlow: (isRafGift: boolean) => {
        set((state) => {
          state.isRafGiftFlow = isRafGift;
        });
      },
      setSiteBannerText: (bannerText: string | undefined) => {
        set((state) => {
          state.siteBannerText = bannerText;
        });
      },
      setPromoType: (promoType: PromoType) => {
        set((state) => {
          state.promoType = promoType;
        });
      },
      setStripePaymentRequest: (stripePaymentRequest: StripePaymentRequest) => {
        set((state) => {
          state.stripePaymentRequest = stripePaymentRequest;
        });
      },
      setAddressVerificationStatus: (status: AddressVerificationStatus) => {
        set((state) => {
          state.addressVerificationStatus = status;
        });
      },
    })),
  );
};
