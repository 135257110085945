import type {
  AccessoryGroup,
  AccessoryProduct,
} from 'services/generated/growth-content-service';
import type { AccessoryCategory } from 'services/generated/commerce-service';
import { ProductType, type PackProduct } from '../types';
import {
  collapsePrices,
  findSubCategory,
  getAllProductsFromCategory,
  getChildPackProductsFromCatalog,
  isBattery,
} from './productHelpers';

export const isPackGroup = (group: AccessoryGroup): boolean => {
  return group.group_type === 'pack';
};

export const isPackProductEqual = (
  product1: PackProduct,
  product2: PackProduct,
): boolean => {
  return (
    product1.id === product2.id &&
    product1.pack_item_ids.every((id) => product2.pack_item_ids.includes(id))
  );
};

export const buildPackProductFromSelections = (
  pack: AccessoryProduct,
  selectedItems: AccessoryProduct[],
): PackProduct => {
  const minQuantity: number =
    selectedItems
      .map((item) => item.inventory_information?.min_quantity ?? 0)
      .sort((minA, minB) => minA - minB)
      .pop() ?? 0;

  const maxQuantity: number =
    selectedItems
      .map((item) => item.inventory_information?.max_quantity ?? 0)
      .sort((maxA, maxB) => maxB - maxA)
      .pop() ?? 0;

  const descriptions = selectedItems
    .map((item) => item.cart_information.description)
    .filter((description) => description.length > 0);

  const itemIds = selectedItems.map((item) => item.id);

  return {
    id: pack.id,
    accessory_type: 'pack',
    product_type: ProductType.ACCESSORY,
    display_price: pack.display_price,
    subtotal: pack.subtotal,
    inventory_information: {
      min_quantity: minQuantity,
      max_quantity: maxQuantity,
    },
    cart_information: {
      image: pack.cart_information.image,
      name: pack.cart_information.name,
      descriptions,
    },
    pack_item_ids: itemIds,
    percent_discount: pack.percent_discount,
  };
};

export const countBatteriesInPack = (
  pack: PackProduct,
  accessories: AccessoryGroup[],
): number => {
  let batteryCount = 0;
  const packItemAccessoryProducts = getProductsFromPack(accessories, pack);
  packItemAccessoryProducts.forEach((product) => {
    batteryCount += isBattery(product) ? 1 : 0;
  });
  return batteryCount;
};

//Given an accessory group and a product ID, find the AccessoryProduct that corresponds to the individual item in the accessory group (ex. red band)
export const findProductInAccessoryGroup = (
  accessoryGroup: AccessoryGroup,
  productID: string,
): AccessoryProduct | undefined => {
  return accessoryGroup.products.find((product) => product.id === productID);
};

//Given available accessories and a pack ID, find the AccessoryGroup that contains solely the pack AccessoryProduct (ex. basics pack)
export const findAccessoryGroupForProduct = (
  accessories: AccessoryGroup[],
  productID: string,
): AccessoryGroup | undefined => {
  return accessories.find((accessory) =>
    accessory.products.some((product) => product.id === productID),
  );
};

// Given available accessories and a pack product, finds the AccessoryProduct objects of each individual product in the pack
// For example: PackProduct (Basics Pack) -> [Band AccessoryProduct, Battery AccessoryProduct]
export const getProductsFromPack = (
  accessories: AccessoryGroup[],
  packProduct: PackProduct,
): AccessoryProduct[] => {
  const products: AccessoryProduct[] = [];
  packProduct.pack_item_ids.forEach((packItemId) => {
    const packAccessoryGroup = findAccessoryGroupForProduct(
      accessories,
      packItemId,
    );
    if (packAccessoryGroup) {
      const packItemAccessory = findProductInAccessoryGroup(
        packAccessoryGroup,
        packItemId,
      );
      if (packItemAccessory) {
        products.push(packItemAccessory);
      }
    }
  });
  return products;
};

// Is every item combination in the pack the same price?
export const isPackSamePrice = (
  childProducts: AccessoryCategory[],
): boolean => {
  return childProducts.every((productCategory) => {
    const products = getAllProductsFromCategory(productCategory);
    return collapsePrices(products).size === 1;
  });
};

// Get the minimum price of a pack
export const getMinPackPrice = (childProducts: AccessoryCategory[]): number => {
  let minPrice = 0;
  childProducts.forEach((productCategory) => {
    const products = getAllProductsFromCategory(productCategory);
    minPrice += Math.min(...collapsePrices(products));
  });
  return minPrice;
};

export const getParentCategoryFromChild = (
  childCategoryId: string,
  categories: AccessoryCategory[],
): AccessoryCategory | undefined => {
  return categories.find((group) => {
    // If we find the child group return the group
    const maybeNode = findSubCategory(childCategoryId, group);
    if (maybeNode !== undefined) {
      return group;
    }
  });
};
