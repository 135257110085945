import type {
  AddressField,
  AddressFieldErrorType,
  AddressFieldValidationRequirements,
  AddressFieldValidationResponse,
  Country,
  Province,
} from '../types/address';
import {
  ADDRESS_REQUIREMENTS_BY_COUNTRY,
  ALL_COUNTRIES,
  PROVINCES_BY_COUNTRY,
  SUPPORTED_COUNTRIES,
} from '../types/address';
import type { Region } from '../types/internationalization';

export * from './addressHelpers/deprecated';

export const getCountriesForRegion = (region: Region): Country[] => {
  return SUPPORTED_COUNTRIES.filter((country) => country.region === region);
};

export const getProvincesForCountry = (country: string): Province[] => {
  return PROVINCES_BY_COUNTRY[country] ?? [];
};

export const getProvinceNameFromCode = (
  countryCode2: string,
  provinceCode: string,
): string | undefined => {
  const provinceName = PROVINCES_BY_COUNTRY[countryCode2]?.find(
    (province) => province.code === provinceCode,
  )?.name;
  return provinceName ?? provinceCode;
};

export const getAddressRequirementsForCountry = (
  country: string,
  orderTotalsRequireAddressID = true,
): AddressField[] => {
  // orderTotalsRequireAddressID is decided based on order total alone (SA requirements, default true)
  let addressRequirements = ADDRESS_REQUIREMENTS_BY_COUNTRY[country] ?? [];
  if (country === 'SA' && !orderTotalsRequireAddressID) {
    addressRequirements = addressRequirements.filter((item) => {
      return item.type !== 'identification_number';
    });
  }
  return addressRequirements;
};

export const modifyAddressForCountry = (address) => {
  if (address?.country === 'QA') {
    return {
      ...address,
      line2: `${(address.line2 || '').trim()} ${(
        address.line3 || ''
      ).trim()}`.trim(),
      line3: undefined,
    };
  }
  return address;
};

/**
 * Validates a single field of a user-entered address.
 * Use react-hook-form's register to do this validation instead if possible.
 * @param field Address field / requirements
 * @param input User inputted value
 * @returns If the field passed all validations or if not, what validation failed
 */
export const validateAddressInput = (
  field: AddressField,
  input?: string,
): AddressFieldValidationResponse => {
  if (!input) {
    return field.required
      ? { isValid: false, errorType: 'required' }
      : { isValid: true };
  }
  if (field.pattern && !field.pattern.exec(input)) {
    return { isValid: false, errorType: 'pattern' };
  }
  if (field.minLength && input.length < field.minLength) {
    return { isValid: false, errorType: 'minLength' };
  }
  if (field.maxLength && input.length > field.maxLength) {
    return { isValid: false, errorType: 'maxLength' };
  }
  return { isValid: true };
};

export const getAddressFieldValidationRequirements = (
  field: AddressField,
): AddressFieldValidationRequirements => {
  return {
    required: field.required,
    minLength: field.minLength,
    maxLength: field.maxLength,
    pattern: field.pattern,
  };
};

export const getAddressFieldErrorKey = (
  field: AddressField,
  errorType: AddressFieldErrorType,
): string => {
  if (errorType === 'maxLength') return 'maxLengthError';
  return `validityError${field.example ? 'WithExample' : ''}`;
};

export const getAlpha3FromAlpha2 = (alpha2: string) => {
  return (
    ALL_COUNTRIES.find((country) => country.alpha2 === alpha2)?.alpha3 ?? ''
  );
};

export const getAlpha2FromAlpha3 = (alpha3: string) => {
  return (
    ALL_COUNTRIES.find((country) => country.alpha3 === alpha3)?.alpha2 ?? ''
  );
};
