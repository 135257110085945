import type {
  AccessoryGroup,
  ProductVariant,
} from 'services/generated/growth-content-service';
import type {
  Product,
  Variant,
  AccessoryCategory,
} from 'services/generated/commerce-service';
import type {
  AnyProduct,
  ProductOption,
  AccessoryProduct,
  VariantInformation,
} from '../types';
import { ProductType } from '../types';
import {
  getInseamLabel,
  getPriceValues,
  getSizeLabel,
  hasInseamAttributes,
  hasSizeAttributes,
  inseamVariantSorter,
  sizeVariantSorter,
} from './variantHelpers';

const SIZE_ORDER = [
  'xxxs',
  '3xs',
  'xxs',
  '2xs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  '2xl',
  'xxxl',
  '3xl',
];

export const isProductType = (
  cartProduct: AnyProduct,
  productType: ProductType,
): boolean => {
  return cartProduct.product_type === productType;
};

export const isAccessory = (cartProduct: AnyProduct): boolean => {
  return isProductType(cartProduct, ProductType.ACCESSORY);
};

export const isDevice = (cartProduct: AnyProduct): boolean => {
  return isProductType(cartProduct, ProductType.DEVICE);
};

export const isMembership = (cartProduct: AnyProduct): boolean => {
  return isProductType(cartProduct, ProductType.MEMBERSHIP);
};

export const isWhoopYourWay = (cartProduct: AnyProduct): boolean => {
  if (!isProductType(cartProduct, ProductType.ACCESSORY)) return false;
  const accessoryProduct = cartProduct as AccessoryProduct;
  return accessoryProduct.accessory_type === 'whoop_your_way';
};

export const isEngraving = (cartProduct: AnyProduct): boolean => {
  if (!isProductType(cartProduct, ProductType.ACCESSORY)) return false;
  const accessoryProduct = cartProduct as AccessoryProduct;
  return accessoryProduct.accessory_type === 'engraving';
};

export const isPack = (cartProduct: AnyProduct): boolean => {
  if (!isProductType(cartProduct, ProductType.ACCESSORY)) return false;
  const accessoryProduct = cartProduct as AccessoryProduct;
  return accessoryProduct.accessory_type === 'pack';
};

export const isBattery = (cartProduct: AnyProduct): boolean => {
  if (!isProductType(cartProduct, ProductType.ACCESSORY)) return false;
  const accessoryProduct = cartProduct as AccessoryProduct;
  return accessoryProduct.accessory_type === 'hardware';
};

export const sortColors = (
  unsortedColors: ProductVariant[],
  products: AccessoryProduct[],
): ProductVariant[] => {
  const sortedColors = new Map<string, ProductVariant>();

  products.forEach((product) => {
    if (
      product.variant_information.color &&
      !sortedColors.get(product.variant_information.color)
    ) {
      const color = unsortedColors.find(
        (_color) => _color.handle === product.variant_information.color,
      );
      if (color) sortedColors.set(color.handle, color);
    }
  });

  return Array.from(sortedColors.values());
};

export const sortSizes = (sizes: ProductOption[]): ProductOption[] =>
  sizes
    .slice()
    .sort(
      (option1, option2) =>
        SIZE_ORDER.indexOf(option1.handle.toLowerCase()) -
        SIZE_ORDER.indexOf(option2.handle.toLowerCase()),
    );

export const sortInseams = (inseams: ProductOption[]): ProductOption[] => {
  return inseams.slice().sort((option1, option2) => {
    if (option1.handle < option2.handle) {
      return -1;
    }
    return 1;
  });
};

export const isAccessoryProductOutOfStock = (
  product: AccessoryProduct,
): boolean => {
  return product.inventory_information
    ? product.inventory_information.max_quantity === 0
    : true;
};

/**
 * Searches for a product that contains the provided variant information
 */
export const findProductByVariant = (
  group: AccessoryGroup,
  variant: VariantInformation,
): AccessoryProduct | undefined => {
  return group.products.find((product) => {
    const { color, inseam, size } = product.variant_information;
    return (
      color === variant.color &&
      ((!inseam && !variant.inseam) || inseam === variant.inseam) &&
      ((!size && !variant.size) || size === variant.size)
    );
  });
};

export const findAccessoryProductFromSku = (
  accessories: AccessoryGroup[],
  sku: string,
): AccessoryProduct | undefined => {
  let accessoryProduct: AccessoryProduct | undefined;
  accessories.some((group) => {
    accessoryProduct = group.products.find((product) => product.id === sku);
    return accessoryProduct;
  });
  return accessoryProduct;
};

/**
 * Searches for the "first" product in relation to
 * how the variant swatches will be ordered on the page
 */
export const findFirstProduct = (group: AccessoryGroup): AccessoryProduct => {
  let sortedProducts = [...group.products];

  if (group.inseams.length) {
    const handles = sortInseams(group.inseams).map((inseam) => inseam.handle);
    sortedProducts = sortedProducts.sort(
      (product1, product2) =>
        handles.indexOf(product1.variant_information.inseam ?? '') -
        handles.indexOf(product2.variant_information.inseam ?? ''),
    );
  }

  if (group.sizes.length) {
    const handles = sortSizes(group.sizes).map((size) => size.handle);
    sortedProducts = sortedProducts.sort(
      (product1, product2) =>
        handles.indexOf(product1.variant_information.size ?? '') -
        handles.indexOf(product2.variant_information.size ?? ''),
    );
  }

  if (group.colors.length) {
    const handles = sortColors(group.colors, group.products).map(
      (color) => color.handle,
    );
    sortedProducts = sortedProducts.sort(
      (product1, product2) =>
        handles.indexOf(product1.variant_information.color ?? '') -
        handles.indexOf(product2.variant_information.color ?? ''),
    );
  }

  return (
    sortedProducts.find(
      (product) => (product.inventory_information?.max_quantity ?? 0) > 0,
    ) || sortedProducts[0]
  );
};

/**
 *
 * Ecomm methods:
 *
 */

export const getFirstProductWithVariantInStock = (
  products: Product[],
): Product => {
  return (
    products.find((product) =>
      product.variants.some((variant) => variant.in_stock),
    ) ?? products[0]
  );
};

export const getFirstVariantInStock = (product: Product): Variant => {
  return (
    product.variants.find((variant) => variant.in_stock) ??
    getFirstVariant(product)
  );
};

export const getFirstVariant = (product: Product): Variant => {
  return product.variants[0];
};

export const getRandomVariant = (products: Product[]) => {
  const allVariants = products
    .flatMap((product) => product.variants)
    .filter((variant) => variant.in_stock);
  return allVariants[Math.floor(Math.random() * allVariants.length)];
};

export const getAllProductsFromCategory = (
  category: AccessoryCategory,
): Product[] => {
  if (category.products.length !== 0) {
    return category.products;
  }

  const productList: Product[] = [];
  category.categories.forEach((cat) => productList.push(...cat.products));
  return productList;
};

export const getIsPackProduct = (category: AccessoryCategory) => {
  const products = getAllProductsFromCategory(category);
  return products[0]?.product_type === 'pack';
};

export const getIsWYWProduct = (category: AccessoryCategory) => {
  const products = getAllProductsFromCategory(category);
  return products[0]?.product_type === 'whoop-your-way';
};

export const getChildPackProductsFromCatalog = (
  accessories: AccessoryCategory[],
  pack: AccessoryCategory,
): AccessoryCategory[] => {
  const packProduct = getFirstVariant(pack.products[0]);
  const packProductsWithDetails: AccessoryCategory[] = [];
  packProduct.attributes.forEach((attribute) => {
    const catalogProduct: AccessoryCategory = findSubCategory(
      attribute.value.id,
      // Creating 1 fake category to hold the entire category tree since this helper
      // requires a category as input, not a list
      {
        category_id: 'id',
        categories: [...accessories],
      } as AccessoryCategory,
    );
    if (catalogProduct) packProductsWithDetails.push(catalogProduct);
  });
  return packProductsWithDetails;
};

export const getDefaultVariantsFromProducts = (
  productList: Product[],
): Variant[] => {
  return productList.map((product) => getFirstVariant(product));
};

export const getColorVariantsWithSameAttributes = (
  productList: Product[],
  selectedVariant: Variant,
): Variant[] => {
  if (hasInseamAttributes(selectedVariant)) {
    return productList.map((product) => {
      return (
        product.variants.find((variant) => {
          const currVarSize = getSizeLabel(variant);
          const selectedVarSize = getSizeLabel(selectedVariant);
          const currVarInseam = getInseamLabel(variant);
          const selectedVarInseam = getInseamLabel(selectedVariant);
          return (
            currVarSize === selectedVarSize &&
            currVarInseam === selectedVarInseam
          );
        }) ?? ({} as Variant)
      );
    });
  }
  if (hasSizeAttributes(selectedVariant)) {
    return productList.map((product) => {
      return (
        product.variants.find((variant) => {
          const currVarSize = getSizeLabel(variant);
          const selectedVarSize = getSizeLabel(selectedVariant);
          return currVarSize === selectedVarSize;
        }) ?? ({} as Variant)
      );
    });
  }
  return getDefaultVariantsFromProducts(productList);
};

export const getSizeVariantsWithSameAttributes = (
  selectedProduct: Product,
  selectedVariant: Variant,
): Variant[] => {
  if (!hasSizeAttributes(selectedVariant)) return [];
  if (!hasInseamAttributes(selectedVariant)) {
    return [...selectedProduct.variants].sort(sizeVariantSorter);
  }
  return selectedProduct.variants
    .filter(
      (variant) => getInseamLabel(variant) === getInseamLabel(selectedVariant),
    )
    .sort(sizeVariantSorter);
};

export const getInseamVariantsWithSameAttributes = (
  selectedProduct: Product,
  selectedVariant: Variant,
): Variant[] => {
  if (!hasInseamAttributes(selectedVariant)) return [];
  return selectedProduct.variants
    .filter(
      (variant) => getSizeLabel(variant) === getSizeLabel(selectedVariant),
    )
    .sort(inseamVariantSorter);
};

export const collapsePrices = (products: Product[]): Set<number> => {
  return new Set(
    products.flatMap((product) =>
      product.variants.map(
        (variant) => getPriceValues(variant).originalPrice ?? 0,
      ),
    ),
  );
};

export const findSubCategory = (id: string, category: AccessoryCategory) => {
  if (id === category.category_id) {
    return category;
  } else {
    let subCategory;
    category.categories.forEach((cat) => {
      const result = findSubCategory(id, cat);
      if (result !== undefined) {
        subCategory = result;
      }
    });
    return subCategory;
  }
};
