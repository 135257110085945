import { ALL_LANGUAGES, DEFAULT_LANGUAGE } from '../types/internationalization';

export const defaultNS = 'checkout';

export const getOptions = (
  lng: string = DEFAULT_LANGUAGE,
  ns: string = defaultNS,
) => {
  return {
    // debug: true,
    supportedLngs: ALL_LANGUAGES,
    fallbackLng: DEFAULT_LANGUAGE,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
};
