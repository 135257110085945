import type { PromoInfo } from 'ui';

export const getReferralType = (promoInfo?: PromoInfo): string | undefined => {
  if (!promoInfo) return;
  const { referral, affiliate } = promoInfo;
  if (!referral && !affiliate) return;

  if (affiliate) return 'whoop_affiliate';

  if (referral?.code.source) {
    let source = referral.code.source;
    if (source === 'app') {
      source = referral.code.community_id ? 'team' : 'individual';
    }
    return source;
  }

  return referral?.code.community_id ? 'team' : 'individual';
};
