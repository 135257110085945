import type { Language } from '../types/internationalization';

/**
WHOOP intl formatting guide: https://docs.google.com/spreadsheets/d/1LtEU11IxZLC_DUUSXxD45aKvjZ9KrjCIVyN6iAQTQjw/edit?pli=1#gid=0

English	4/27/1989
German	27.4.1989
Spanish (MX)	27/4/1989
Italian	27/4/1989
French	27/04/1989
Portuguese (PT)	27/04/1989
 */

export type DateFormatType = 'long' | 'short';

const getDateTimeOptions = (
  language: Language,
  dateFormatType: DateFormatType,
  formatOptions?: Intl.DateTimeFormatOptions,
): Intl.DateTimeFormatOptions => {
  // Custom format
  if (formatOptions && Object.keys(formatOptions).length) {
    return formatOptions;
  }
  // DatePicker formats
  else if (dateFormatType === 'short') {
    if (['pt', 'fr'].includes(language)) {
      return { month: '2-digit', day: '2-digit', year: 'numeric' };
    } // otherwise it is: it, es, de, or en
    return { month: 'numeric', day: 'numeric', year: 'numeric' };
  }
  // Default DateTimeOption return has dateFormatType 'long': e.g. March 6, 2024
  return { month: 'long', day: 'numeric', year: 'numeric' };
};

/**
 * Takes in a date and returns the formatted date based on the locale passed in
 * @param date Date
 * @param language Language
 * @param formatOptions Intl.DateTimeFormatOptions
 * @returns string with the correctly formatted date (ex. EN = March 4th, 2024 ES = del 4 de marzo de 2024)
 */
export function formatDate(
  date: Date,
  language: Language,
  formatOptions?: Intl.DateTimeFormatOptions,
  dateFormatType: DateFormatType = 'long',
): string {
  return Intl.DateTimeFormat(
    language,
    getDateTimeOptions(language, dateFormatType, formatOptions),
  ).format(date);
}
