import type { JFCurrency } from '@whoop/i18n/types/internationalization';

// This method should only need to be used to help ensure that types (Currency and JFCurrency) match and for safety.
// There shouldn't be any place where a user in the JF would have their currency input as 'inr' or 'qar'.
export function adjustToJFCurrency(currency: string): JFCurrency {
  if (currency === 'inr') {
    return 'usd';
  } else if (currency === 'qar') {
    return 'aed';
  }
  return currency as JFCurrency;
}
