import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import type {
  ApplicationScheme,
  PromoRule,
} from 'services/generated/growth-content-service';
import { i18n } from '@whoop/i18n/lang/client';
import { formatPriceWithSiteValues } from '@whoop/i18n';
import type {
  CartProduct,
  CartItemType,
  CheckoutCartItem,
  MembershipProduct,
  PromoCode,
  PromoCodeApplicationScheme,
  PromoCodeRule,
  PromoCodeRuleType,
  PromoCodeState,
  PromoInfo,
  Referrer,
} from '../types';
import { ProductType } from '../types';

export const PROMO_CODE_REGEX = /[a-zA-Z0-9]*/;

//-- Promo code rules --//
// Rules specify single items that a promotion does (INCLUDED) or does not (EXCLUDED) apply to.
// They can also specify items that must be (REQUIRED) or cannot be (BLOCKED) in the cart to apply the promotion.
const getRulesByRuleType = (
  rules: PromoCodeRule[] | PromoRule[],
  type: PromoCodeRuleType,
): PromoCodeRule[] | PromoRule[] => {
  return rules.filter((rule) => rule.rule_type === type);
};

const getRulesByRuleTypeAndItemType = (
  rules: PromoCodeRule[] | PromoRule[],
  ruleType: PromoCodeRuleType,
  itemType: string,
): PromoCodeRule[] | PromoRule[] => {
  return rules.filter(
    (rule) => rule.rule_type === ruleType && rule.item_type === itemType,
  );
};

const ruleAppliesToProduct = (
  rule: PromoCodeRule | PromoRule,
  product: CartProduct,
): boolean => {
  return product.item.id.startsWith(rule.sku);
};

export const cartPassesRequiredAndBlockedRules = (
  rules: PromoCodeRule[] | PromoRule[],
  cartProducts: CartProduct[],
): boolean => {
  const requiredRules = getRulesByRuleType(rules, 'REQUIRED');
  const passesRequiredRules =
    !requiredRules.length ||
    requiredRules.every((rule) =>
      cartProducts.some((product) => ruleAppliesToProduct(rule, product)),
    );
  if (!passesRequiredRules) return false;

  const blockedRules = getRulesByRuleType(rules, 'BLOCKED');
  const passesBlockedRules =
    !blockedRules.length ||
    blockedRules.every((rule) =>
      cartProducts.every((product) => !ruleAppliesToProduct(rule, product)),
    );
  return passesBlockedRules;
};

export const modifyCartProductForRules = (
  rules: PromoCodeRule[] | PromoRule[],
  cartProduct: CartProduct,
): CartProduct | undefined => {
  const includedRules = getRulesByRuleTypeAndItemType(
    rules,
    'INCLUDED',
    cartProduct.item.product_type,
  );
  if (includedRules.length) {
    const relevantRule = includedRules.find((rule) =>
      ruleAppliesToProduct(rule, cartProduct),
    );
    if (!relevantRule) return;
    return {
      ...cartProduct,
      quantity: Math.min(
        cartProduct.quantity,
        relevantRule.max_quantity || cartProduct.quantity,
      ),
    };
  }

  const excludedRules = getRulesByRuleTypeAndItemType(
    rules,
    'EXCLUDED',
    cartProduct.item.product_type,
  );
  if (excludedRules.length) {
    const relevantRule = excludedRules.find((rule) =>
      ruleAppliesToProduct(rule, cartProduct),
    );
    if (relevantRule) return;
  }

  return cartProduct;
};

//-- Promo code application schemes --//
// A promotion's application schemes dictate what types of items the discount applies to and what the discount is
const isSchemeSpecificToProductType = (
  scheme: PromoCodeApplicationScheme,
  productType: ProductType,
): boolean => {
  if (productType === ProductType.ACCESSORY) {
    return scheme.application_type === 'accessories';
  }
  return scheme.application_type === productType;
};

const isSchemeGeneric = (scheme: PromoCodeApplicationScheme): boolean => {
  return !scheme.application_type;
};

export const getMembershipTypeApplicationSchemeForCurrency = (
  schemes: PromoCodeApplicationScheme[] | ApplicationScheme[],
  currency: Currency,
): PromoCodeApplicationScheme | ApplicationScheme | undefined => {
  const schemeForCurrency = schemes.find(
    (scheme) =>
      scheme.currency === currency && scheme.application_type === 'membership',
  );
  if (schemeForCurrency) return schemeForCurrency;
  return schemes.find(
    (scheme) => !scheme.currency && scheme.application_type === 'membership',
  );
};

export const getApplicationSchemeForCurrency = (
  schemes: PromoCodeApplicationScheme[] | ApplicationScheme[],
  currency: Currency,
): PromoCodeApplicationScheme | ApplicationScheme | undefined => {
  const schemeForCurrency = schemes.find(
    (scheme) => scheme.currency === currency,
  );
  if (schemeForCurrency) return schemeForCurrency;
  return schemes.find((scheme) => !scheme.currency);
};

const getApplicationSchemesForCart = (
  schemes: PromoCodeApplicationScheme[] | ApplicationScheme[],
  currency: Currency,
): PromoCodeApplicationScheme[] => {
  const groupedSchemes: Record<
    string,
    (PromoCodeApplicationScheme | ApplicationScheme)[]
  > = {};
  for (const scheme of schemes) {
    const applicationType = scheme.application_type || '';
    if (applicationType in groupedSchemes) {
      groupedSchemes[applicationType].push(scheme);
    } else {
      groupedSchemes[applicationType] = [scheme];
    }
  }

  return Object.entries(groupedSchemes)
    .map(([_, value]) => getApplicationSchemeForCurrency(value, currency))
    .filter((scheme) => scheme !== undefined) as PromoCodeApplicationScheme[];
};

export const getDiscountForApplicationScheme = (
  applicationScheme: PromoCodeApplicationScheme,
  products: CartProduct[],
): number => {
  if (!products.length) return 0;

  const productsTotal = products
    .map((product) => product.item.display_price * product.quantity)
    .reduce((acc, curr) => acc + curr, 0);

  const discountType = applicationScheme.promo_type;
  const discountAmount = applicationScheme.amount;

  switch (discountType) {
    case 'amount_off':
      return Math.min(productsTotal, discountAmount);
    case 'percent_off':
      return Math.round(productsTotal * (discountAmount / 100));
    case 'month_off':
      return Math.round(
        (productsTotal /
          (products[0]?.item as MembershipProduct).trial_months) *
          discountAmount,
      );
    default:
      return 0;
  }
};

const groupProductsByType = (
  cartProducts: CartProduct[],
): Record<ProductType, CartProduct[]> => {
  const groupedProducts: Record<string, CartProduct[]> = {};
  for (const product of cartProducts) {
    const productType = product.item.product_type;
    if (productType in groupedProducts) {
      groupedProducts[productType].push(product);
    } else {
      groupedProducts[productType] = [product];
    }
  }
  return groupedProducts;
};

export const getTotalCartDiscount = (
  promoCode: PromoCodeState,
  cartProducts: CartProduct[],
  currency: Currency,
): number => {
  if (
    !promoCode.rules ||
    !cartPassesRequiredAndBlockedRules(promoCode.rules, cartProducts)
  )
    return 0;

  const productsToDiscount = [...cartProducts]
    .map((product) => modifyCartProductForRules(promoCode.rules || [], product))
    .filter((product) => product !== undefined) as CartProduct[];
  const applicationSchemes = getApplicationSchemesForCart(
    promoCode.application_schemes || [],
    currency,
  );
  const groupedProducts = groupProductsByType(productsToDiscount);

  let genericProducts: CartProduct[] = [];
  let totalDiscount = 0;
  for (const [productType, products] of Object.entries(groupedProducts)) {
    const applicationSchemeForType = applicationSchemes.find((scheme) =>
      isSchemeSpecificToProductType(scheme, productType as ProductType),
    );
    if (applicationSchemeForType) {
      totalDiscount += getDiscountForApplicationScheme(
        applicationSchemeForType,
        products,
      );
    } else {
      genericProducts = genericProducts.concat(products);
    }
  }

  const genericApplicationScheme = applicationSchemes.find((scheme) =>
    isSchemeGeneric(scheme),
  );
  if (genericApplicationScheme) {
    totalDiscount += getDiscountForApplicationScheme(
      genericApplicationScheme,
      genericProducts,
    );
  }

  return totalDiscount;
};

export const isMonthOffPromoCode = (promoCode?: PromoCode): boolean => {
  if (!promoCode?.application_schemes) return false;
  return Boolean(
    promoCode.application_schemes.find(
      (scheme) => scheme.promo_type === 'month_off',
    ),
  );
};

export const codeAppliesToMembership = (
  promoCode: PromoCode,
  membershipSku: string,
): boolean => {
  const membershipRules =
    promoCode.rules?.filter((rule) => rule.item_type === 'membership') ?? [];

  const membershipIncluded = Boolean(
    membershipRules.find(
      (rule) =>
        rule.rule_type === 'INCLUDED' && membershipSku.startsWith(rule.sku),
    ),
  );

  const hasExcludedRules = membershipRules.find(
    (rule) => rule.rule_type === 'EXCLUDED',
  );
  const membershipExcluded = membershipRules.find(
    (rule) =>
      rule.rule_type === 'EXCLUDED' && membershipSku.startsWith(rule.sku),
  );

  return (
    membershipIncluded || (Boolean(hasExcludedRules) && !membershipExcluded)
  );
};

export const getReferrer = (promoInfo?: PromoInfo): Referrer | undefined => {
  if (promoInfo?.referral) {
    return promoInfo.referral.referrer;
  }
  if (promoInfo?.affiliate) {
    return {
      first_name:
        promoInfo.affiliate.name || i18n.t('joinLandingPage:yourFriend'),
      avatar_url: promoInfo.affiliate.avatar_url,
    };
  }
};

export const getMembershipPromoDisclaimerByCurrency = (
  applicationSchemas: ApplicationScheme[],
  currency: Currency,
): ApplicationScheme | undefined => {
  const applicationScheme = getMembershipTypeApplicationSchemeForCurrency(
    applicationSchemas,
    currency,
  );
  if (!applicationScheme) return;
  return applicationScheme;
};

/**
 * This grabs some of the copy to let the user know that a RAF/Affiliate promo has been applied throughout the flow
 * @param promoInfo {JoinFlowContent} - The promo info object
 * @param referrer {Referrer} - The referrer object
 * @param currency {Currency} - The currency string
 * @param language {Language} - The language string
 * @returns
 */
export const getPromoDisclaimer = ({
  promo,
  referrer,
  currency,
  language,
  region,
  location = 'primary',
}: {
  promo: ApplicationScheme | undefined;
  referrer: Referrer;
  currency: Currency;
  language: Language;
  region: Region;
  location: 'primary' | 'secondary';
}): string => {
  const { first_name: firstName } = referrer;
  const copyLocations = {
    primary: {
      month_off: 'joinLandingPage:referral.gave-you-one-month',
      percent_off: 'joinLandingPage:referral.gave-you-percentage-off',
      amount_off: 'joinLandingPage:referral.gave-you-amount-off',
    },
    secondary: {
      month_off: 'orderPage:oneMonthCallout',
      percent_off: 'orderPage:percentCallout',
      amount_off: 'orderPage:amountCallout',
    },
  };

  const copyLocation = copyLocations[location];

  if (!promo) return '';

  if (promo.application_type === 'membership') {
    switch (promo.promo_type) {
      case 'month_off':
        return i18n.t(copyLocation.month_off, {
          name: firstName,
        });
      case 'percent_off':
        return i18n.t(copyLocation.percent_off, {
          name: firstName,
          percentage: promo.amount,
        });
      case 'amount_off':
        return i18n.t(copyLocation.amount_off, {
          name: firstName,
          amount: formatPriceWithSiteValues(promo.amount, {
            currency,
            language,
            region,
          }),
        });
      default:
        return '';
    }
  }
  return '';
};

export const formatCartProductsForDiscounting = (
  product: CartProduct[],
): CheckoutCartItem[] =>
  product.map((prod) => {
    return {
      sku: prod.item.id,
      quantity: prod.quantity,
      child_items: [],
    };
  });

export const formatUrlPromoInfo = (
  urlCode: string,
  promo: PromoInfo,
): PromoInfo => {
  const promoCode = promo.promo_code;
  return {
    ...promo,
    promo_code: { ...promoCode, code: urlCode.toUpperCase() },
  };
};

export const getPromoPercentDiscount = (
  product: CartItemType,
  currency: Currency,
  displayPrice: number | undefined,
  promoCode: PromoCodeState,
): number => {
  return (
    (getTotalCartDiscount(
      promoCode,
      [
        {
          item: product,
          quantity: 1,
        },
      ],
      currency,
    ) *
      100.0) /
    (displayPrice ?? product.display_price)
  );
};
