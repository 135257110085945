import type {
  AnyProduct,
  CartProduct,
  MembershipProduct,
  JoinProducts,
  PackProduct,
  MembershipType,
} from 'ui';
import {
  ProductType,
  isAccessory,
  isDevice,
  isEngraving,
  isMembership,
  isPack,
  isProductType,
} from 'ui';
import { getDeviceProductForCart } from 'ui/utils/deviceHelpers';
import { isPackProductEqual } from 'ui/utils/packHelpers';

export const isCartProductEqual = (
  product1: CartProduct,
  product2: CartProduct,
): boolean => {
  if (isPack(product1.item) && isPack(product2.item)) {
    return isPackProductEqual(
      product1.item as PackProduct,
      product2.item as PackProduct,
    );
  }
  return product1.item.id === product2.item.id;
};

export const findExactCartProduct = (
  cartProducts: CartProduct[],
  cartProduct: CartProduct,
): CartProduct | undefined => {
  return cartProducts.find((product) =>
    isCartProductEqual(product, cartProduct),
  );
};

export const findCartProductByType = (
  cartProducts: CartProduct[],
  productType: ProductType,
): CartProduct | undefined => {
  return cartProducts.find((product) =>
    isProductType(product.item, productType),
  );
};

export const replaceCartProducts = (
  cartProducts: CartProduct[],
  ...newProducts: CartProduct[]
): CartProduct[] => {
  let products = [...cartProducts];

  newProducts.forEach((newProduct) => {
    const existingProduct = findCartProductByType(
      products,
      newProduct.item.product_type as ProductType,
    );
    if (existingProduct) {
      products[products.indexOf(existingProduct)] = newProduct;
    } else {
      products = [newProduct, ...products];
    }
  });

  return products;
};

export const addCartProduct = (
  requiredProducts: JoinProducts,
  existingCart: CartProduct[],
  newProduct: CartProduct,
  updateQuantity?: boolean,
): CartProduct[] => {
  let newCart = [...existingCart];
  if (isMembership(newProduct.item)) {
    const membership = newProduct.item as MembershipProduct;
    const requiredDevice = getDeviceProductForCart(
      requiredProducts.devices,
      membership,
    );
    const deviceCartProduct = {
      item: requiredDevice,
      quantity: newProduct.quantity,
    };
    if (membership.membership_type === 'family') {
      deviceCartProduct.quantity = membership.family_size || 1;
    }
    if (membership.membership_type === 'trial') {
      newCart = removeEngraving(newCart);
    }
    return replaceCartProducts(newCart, newProduct, deviceCartProduct);
  }
  if (isAccessory(newProduct.item)) {
    const existingProduct = findExactCartProduct(newCart, newProduct);
    return existingProduct
      ? updateCartProductQuantity(
          newCart,
          newProduct,
          updateQuantity
            ? newProduct.quantity
            : existingProduct.quantity + newProduct.quantity,
        )
      : [...newCart, newProduct];
  }
  if (isDevice(newProduct.item)) {
    const existingProduct = findExactCartProduct(newCart, newProduct);
    const membershipInCart = getMembershipCartProductInCart(newCart);
    const familySize = membershipInCart?.item.family_size;
    const quantity = familySize || membershipInCart?.quantity || 1;
    return existingProduct
      ? updateCartProductQuantity(newCart, newProduct, quantity)
      : [...newCart, newProduct];
  }
  return newCart;
};

export const removeCartProduct = (
  existingCart: CartProduct[],
  productToRemove: CartProduct,
): CartProduct[] => {
  return [...existingCart].filter(
    (product) => !isCartProductEqual(product, productToRemove),
  );
};

export const updateCartProductQuantity = (
  existingCart: CartProduct[],
  productToUpdate: CartProduct,
  newQuantity: number,
): CartProduct[] => {
  if (!isQuantityAllowed(productToUpdate.item, newQuantity))
    return existingCart;
  if (isMembership(productToUpdate.item)) {
    return [...existingCart].map((product) => {
      if (
        isCartProductEqual(product, productToUpdate) ||
        isDevice(product.item)
      ) {
        // each membership comes with a device, so device quantity must always match membership quantity
        return { ...product, quantity: newQuantity };
      }
      if (isEngraving(product.item)) {
        // you may have as many engravings as memberships
        const newInventoryInformation = {
          min_quantity: 0,
          max_quantity: newQuantity,
        };
        return {
          item: {
            ...product.item,
            inventory_information: newInventoryInformation,
          },
          quantity: Math.min(newQuantity, product.quantity),
        };
      }
      return product;
    });
  }
  return [...existingCart]
    .map((product) =>
      isCartProductEqual(product, productToUpdate)
        ? { ...productToUpdate, quantity: newQuantity }
        : product,
    )
    .filter((product) => product.quantity > 0);
};

export const isQuantityAllowed = (
  product: AnyProduct,
  newQuantity: number,
): boolean => {
  const inventory = product.inventory_information;
  const minQuantity = inventory?.min_quantity ?? 1;
  const maxQuantity = inventory?.max_quantity ?? 1;
  return newQuantity >= minQuantity && newQuantity <= maxQuantity;
};

const removeEngraving = (existingCart: CartProduct[]): CartProduct[] => {
  return [...existingCart].filter((product) => !isEngraving(product.item));
};

export const getCartMembershipType = (
  cartProducts: CartProduct[],
): MembershipType | undefined => {
  const cartMembership = findCartProductByType(
    cartProducts,
    ProductType.MEMBERSHIP,
  );
  if (!cartMembership) return;
  return (cartMembership.item as MembershipProduct).membership_type;
};

export const getMembershipCartProductInCart = (
  cartProducts: CartProduct[],
): CartProduct | undefined => {
  return cartProducts.find(
    (product) => product.item.product_type === ProductType.MEMBERSHIP,
  );
};

export const getDeviceInCart = (cartProducts: CartProduct[]) => {
  return cartProducts.find(
    (product) => product.item.product_type === ProductType.DEVICE,
  );
};
