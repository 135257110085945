import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Formats a string to a handleized string
 * @param {} value
 * @returns
 */
export const handleSize = function (value: string | undefined): string {
  if (!value) return '';
  return value
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/-$/, '')
    .replace(/^-/, '');
};

/**
 * Preload an image given a URL
 */
export function preloadImage(url: string): void {
  new Image().src = url;
}

/**
 * Returns a string of classes. Uses clsx and tailwind-merge
 * @param inputs
 * @returns
 */
export function cltw(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

export * from './accessoriesHelpers';
export * from './checkoutFormHelpers';
export * from './effects';
export * from './totalsHelpers';
export * from './promotionHelpers';
export * from './productHelpers';
export * from './membershipHelpers';
export * from './metadataConfig';
export * from './validation';
export * from './whoopYourWayHelpers';
export * from './shopifyClient';
export * from './urlUtils';
