'use client';

import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import type { ItemContext, PurchaseContext } from 'whoop-analytics';
import analytics from 'whoop-analytics';
import { getReferralType } from 'whoop-analytics/utils';
import type {
  AnalyticsContext,
  ImpactCategories,
  SegmentEvents,
} from 'whoop-analytics/lib/types';
import { AmplitudeEvents, PAGE_VIEWS } from 'whoop-analytics/lib/types';
import type {
  CartProduct,
  OrderTotalType,
  PlacedOrder,
  MembershipProduct,
  AnalyticsPageTitle,
  CartItemType,
} from 'ui';
import { ProductType, findOrderTotal, getCurrentPageFromURL } from 'ui';
import { useShallow } from 'zustand/react/shallow';
import { useState } from 'react';
import { getCookie } from 'cookies-next';
import type { CheckoutAnalytics } from 'services/generated/order-service';
import type { CountryCode } from '@whoop/i18n';
import { isRestOfWorld } from '@whoop/i18n';
import {
  getAccessoryContext,
  getAnalyticsAmount,
} from 'lib/utils/analyticsHelper';
import { findCartProductByType } from 'lib/utils/cartManager';
import { env } from 'env';
import { useSiteStore } from '@/store/SiteStoreProvider';

interface IAnalyticsTotals {
  subtotal: number;
  shipping: number;
  tax: number;
  total: number;
}

interface MembershipContext {
  membership?: number;
  membership_quantity?: number;
  is_trial?: boolean;
  family_size?: number;
}

interface PlacedOrderContext extends MembershipContext {
  order_id: string;
  currency: Currency;
  flow_type: string;
  subtotal_amount: number;
  shipping: number;
  checkout_amount: number;
  accessory_count: number;
  accessory_total: number;
  address_country?: string;
}

interface AmplitudeEventProps extends MembershipContext {
  referral_type?: string;
  currency: Currency;
  language: Language;
  region: Region;
  country: CountryCode;
  promoCode: string | undefined;
  site: string;
  purchase_data?: PurchaseContext;
  flow_type?: string;
  total_accessory_count?: number;
  unique_accessory_count?: number;
  partnership?: string;
  page: string;
  previous_page?: string;
}

const paths: AnalyticsPageTitle[] = ['Initial'];

/**
 * TODO: A lot of these analytics functions should be plain javascript and should be React agnostic.
 * We should be able to use analytics outside of React components by default.
 * Then re-export them in a useAnalytics hook for React components to use.
 */

export function useAnalytics() {
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    asAGift,
    isRafGiftFlow,
    currency,
    language,
    promoInfo,
    promoContent,
    cartProducts,
    region,
    country,
    shippingAddress,
  } = useSiteStore(
    useShallow((state) => ({
      asAGift: state.asAGift,
      isRafGiftFlow: state.isRafGiftFlow,
      currency: state.currency,
      language: state.language,
      promoInfo: state.promoInfo,
      promoContent: state.promoContent,
      cartProducts: state.cartProducts,
      region: state.region,
      country: state.country,
      shippingAddress: state.shippingAddress,
    })),
  );

  const initialize = (): void => {
    if (!isInitialized) {
      analytics.initialize({
        amplitude: {
          key: env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
          options: {
            defaultTracking: {
              attribution: true,
              pageViews: {
                trackOn: 'attribution',
              },
              sessions: false,
              fileDownload: false,
              formInteraction: false,
            },
            cookieOptions: {
              domain: '.whoop.com',
            },
            includeReferrer: true,
            includeUtm: true,
          },
        },
        gtm: {
          id: env.NEXT_PUBLIC_GTM_ID,
        },
        segment: {
          key: env.NEXT_PUBLIC_SEGMENT_API_KEY,
        },
        datadog: {
          applicationId: env.NEXT_PUBLIC_DD_APP_ID,
          clientToken: env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
          service: 'join-flow',
          env: env.NEXT_PUBLIC_ENV,
          enableSessionReplay: true,
        },
        sentry: {
          env: env.NEXT_PUBLIC_ENV,
          dsn: env.NEXT_PUBLIC_SENTRY_DSN,
        },
      });
      setAmplitudeUserProperty('join_flow_language', language);
      if (isRestOfWorld(currency, region)) {
        setAmplitudeUserProperty('row_country', region);
      }
      setIsInitialized(true);
    }
  };

  const trackPageView = (pageName: string): void => {
    analytics.trackPageView({
      page_location: window.location.href.split('?')[0],
      language,
      page_title: pageName,
    });
  };

  const getMembershipCategory = (cartItem: CartItemType): ImpactCategories => {
    if (cartItem.product_type === 'membership') {
      const item = cartItem as MembershipProduct;
      if (item.membership_type === 'prepaid') {
        return item.trial_months === 12 ? '12-month' : '24-month';
      }
      return '';
    }
    return '';
  };

  const getItemContext = (cartProduct: CartProduct): ItemContext => {
    const cartItem = cartProduct.item;
    const cartInfo = cartItem.cart_information;
    return {
      item_id: cartItem.id,
      item_name: cartInfo.name,
      item_variant: cartInfo.description,
      price: getAnalyticsAmount(cartItem.display_price),
      quantity: cartProduct.quantity,
      category: getMembershipCategory(cartItem),
    };
  };

  const trackIrePurchase = (placedOrder: PlacedOrder): void => {
    const analyticsTotals = getPurchaseOrderObject(placedOrder);
    // https://whoopinc.atlassian.net/browse/GRO-2220
    // Track conversion through Impact pixel - identify calls in GTM
    if (analyticsTotals.variables && window.ire !== undefined) {
      window.ire(
        'trackConversion',
        analyticsTotals.variables.orderType === 'trial' ? 43730 : 36994,
        analyticsTotals.variables.joinOrder,
        {
          verifySiteDefinitionMatch: true,
        },
      );
    }
  };

  const trackPurchase = (placedOrder: PlacedOrder): void => {
    const analyticsTotals = getPurchaseOrderObject(placedOrder);
    analytics.trackPurchase(analyticsTotals);
  };

  const trackMembershipSelect = (membership: MembershipProduct): void => {
    // push membership data to the GTM data layer for Pixel tracking
    window.dataLayer.push({ membership });
  };

  const getTotalsAmount = (
    placedOrder: PlacedOrder,
    totalType: OrderTotalType,
  ): number => {
    if (placedOrder.totals.length === 0) return 0;
    const amount = findOrderTotal(placedOrder.totals, totalType)
      ?.display_amount;
    if (!amount) return 0;
    return getAnalyticsAmount(amount);
  };

  const getAnalyticsTotals = (placedOrder?: PlacedOrder): IAnalyticsTotals => {
    if (!placedOrder) {
      return {
        subtotal: 0,
        shipping: 0,
        tax: 0,
        total: 0,
      };
    }
    return {
      subtotal: getTotalsAmount(placedOrder, 'subtotal'),
      shipping: getTotalsAmount(placedOrder, 'shipping'),
      tax: getTotalsAmount(placedOrder, 'tax'),
      total: getTotalsAmount(placedOrder, 'total'),
    };
  };

  const getPurchaseOrderObject = (
    placedOrder: PlacedOrder,
  ): PurchaseContext => {
    const analyticsTotals = getAnalyticsTotals(placedOrder);
    const lineItems = cartProducts.map((product) => getItemContext(product));
    return {
      currency,
      transaction_id: placedOrder.id,
      value: analyticsTotals.total,
      coupon: promoInfo?.promo_code.code,
      shipping: analyticsTotals.shipping,
      tax: analyticsTotals.tax,
      items: lineItems,
      // https://whoopinc.atlassian.net/browse/GRO-2220
      variables: {
        orderType: cartProducts.some(
          (cartProduct) =>
            cartProduct.item.product_type === 'membership' &&
            (cartProduct.item as MembershipProduct).membership_type === 'trial',
        )
          ? 'trial'
          : 'prepaid',
        joinOrder: {
          orderId: placedOrder.id,
          customerId: placedOrder.user_id,
          currencyCode: currency,
          orderPromoCode: promoInfo?.promo_code.code ?? '',
          orderDiscount: getTotalsAmount(placedOrder, 'discount'),
          Customercountry: region,
          // Impact pixel expects same data in different format
          // This gets picked up as a custom variable on the GTM event
          items: lineItems.map((item) => ({
            subTotal: item.price,
            category: item.category,
            sku: item.item_id,
            quantity: item.quantity,
            name: item.item_name,
          })),
        },
      },
    };
  };

  const getMembershipContextFromCart = (): MembershipContext => {
    const membership = findCartProductByType(
      cartProducts,
      ProductType.MEMBERSHIP,
    );
    const page = getCurrentPageFromURL();
    if (!membership) return {};
    const membershipItem = membership.item as MembershipProduct;
    const family_size =
      membershipItem.membership_type === 'family' && page !== 'Membership'
        ? membershipItem.family_size
        : undefined;
    return {
      membership: membershipItem.trial_months * 30,
      membership_quantity: membership.quantity,
      is_trial: membershipItem.membership_type === 'trial',
      family_size,
    };
  };

  const getPlacedOrderContext = (
    placedOrder: PlacedOrder,
  ): PlacedOrderContext => {
    const membership = findCartProductByType(
      cartProducts,
      ProductType.MEMBERSHIP,
    );
    let membershipTrialDays;
    let isTrial = false;
    if (membership) {
      const membershipItem = membership.item as MembershipProduct;
      membershipTrialDays = membershipItem.trial_months * 30;
      isTrial = membershipItem.membership_type === 'trial';
    }

    const analyticsTotals = getAnalyticsTotals(placedOrder);
    const { accessoryCount, accessoryTotal } =
      getAccessoryContext(cartProducts);
    const membershipContextFromCart = getMembershipContextFromCart();

    return {
      ...membershipContextFromCart,
      order_id: placedOrder.id,
      currency,
      membership: membershipTrialDays ?? 0,
      membership_quantity: membership?.quantity ?? 0,
      is_trial: isTrial,
      flow_type: asAGift ? 'gift' : 'join',
      subtotal_amount: analyticsTotals.subtotal,
      shipping: analyticsTotals.shipping,
      checkout_amount: analyticsTotals.total,
      accessory_count: accessoryCount,
      accessory_total: accessoryTotal,
      address_country: shippingAddress?.country,
    };
  };

  const trackAmplitudeEvent = (
    eventName: AmplitudeEvents,
    options?: AnalyticsContext,
  ): void => {
    const referralType = getReferralType(promoInfo);
    const membershipContextFromCart = getMembershipContextFromCart();
    // eslint-disable-next-line prefer-const
    let { accessories, accessoryCount, uniqueAccessories } =
      getAccessoryContext(cartProducts);
    const page = getCurrentPageFromURL();
    if (PAGE_VIEWS.includes(eventName) && page !== paths[paths.length - 1])
      paths.push(page);
    const previousPage =
      page === paths[paths.length - 1]
        ? paths[paths.length - 2]
        : paths[paths.length - 1];

    /**
     * TODO: Remove if block
     * This is a temporary work around to fix out of sync issues when adding and removing accessories from the cart on the accessories page.
     */
    if (page === 'Accessories') {
      const sku = options?.sku;
      const accessory = accessories.find(
        (accessory) => accessory.item.id === sku,
      );
      if (eventName === AmplitudeEvents.AddedItemToCart) {
        if (!accessory) {
          uniqueAccessories++;
        }
        accessoryCount++;
      }
      if (eventName === AmplitudeEvents.RemovedItemFromCart) {
        if (accessory?.quantity === 1) {
          uniqueAccessories--;
        }
        accessoryCount--;
      }
    }

    let family_size = membershipContextFromCart.family_size;
    if (options?.family_size) {
      const optionsFamilySize = options?.family_size as string;
      family_size = parseInt(optionsFamilySize);
    }
    const eventProperties: AmplitudeEventProps = {
      ...membershipContextFromCart,
      family_size,
      total_accessory_count: accessoryCount,
      unique_accessory_count: uniqueAccessories,
      flow_type: asAGift ? 'gift' : 'join',
      referral_type: isRafGiftFlow ? 'gift_authenticated' : referralType,
      partnership: promoContent?.partnership_name,
      currency,
      language,
      region,
      country,
      promoCode: promoInfo?.promo_code.code,
      site: 'join',
      page,
      previous_page: previousPage,
    };

    analytics.trackAmplitudeEvent(eventName, {
      ...options,
      ...eventProperties,
    });
  };

  const trackPlacedOrder = (
    placedOrder: PlacedOrder,
    context?: AnalyticsContext,
  ): void => {
    const placedOrderContext = {
      ...getPlacedOrderContext(placedOrder),
      ...context,
    };
    // Build association between the current join flow session and new account if a new account was created
    if (analytics.setAmplitudeUserId && placedOrder.user_id) {
      analytics.setAmplitudeUserId(placedOrder.user_id);
    }
    trackAmplitudeEvent(AmplitudeEvents.PlacedOrder, placedOrderContext);
    trackPurchase(placedOrder);
  };

  const setAmplitudeUserProperty = (key: string, value: string): void => {
    analytics.setAmplitudeUserProperty(key, value);
  };

  const unsetAmplitudeUserProperty = (key: string): void => {
    analytics.unsetAmplitudeUserProperty(key);
  };

  const identifySegmentUser = (userId?: number): void => {
    if (!analytics.identify || !userId) return;
    analytics.identify({
      id: userId.toString(),
    });
  };

  const trackSegmentEvent = (
    eventName: SegmentEvents,
    context: AnalyticsContext,
  ): void => {
    analytics.trackSegmentEvent(eventName, context);
  };

  const getCheckoutAnalytics = (): CheckoutAnalytics => {
    return {
      source: getCookie('utm_source'),
      campaign: getCookie('utm_campaign'),
      medium: getCookie('utm_medium'),
      content: getCookie('utm_content'),
      // Facebook CAPI: https://developers.facebook.com/docs/marketing-api/conversions-api/
      facebook: {
        client_user_agent: navigator.userAgent,
        action_source: 'website',
        event_source_url: window.location.href,
        fbc: getCookie('_fbc'),
        fbp: getCookie('_fbp'),
      },
      device_id: analytics?.getDeviceId?.() || undefined,
      session_id: analytics?.getSessionId?.() || undefined,
      category: 'web JF',
    };
  };

  return {
    initialize,
    trackPageView,
    trackPlacedOrder,
    trackIrePurchase,
    identifySegmentUser,
    trackSegmentEvent,
    trackAmplitudeEvent,
    setAmplitudeUserProperty,
    unsetAmplitudeUserProperty,
    trackError: analytics.trackError,
    isInitialized,
    trackMembershipSelect,
    getCheckoutAnalytics,
    getDeviceId: analytics.getDeviceId,
    getSessionId: analytics.getSessionId,
  };
}
