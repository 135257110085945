/* Any changes to this file should also be changed here: https://whoopinc.atlassian.net/wiki/spaces/PROD/pages/4175429654/Intl+Address+Validation+Requirements */

import {
  type AddressField,
  DEFAULT_FIRST_NAME_FIELD,
  DEFAULT_LEGAL_FIRST_NAME_FIELD,
  DEFAULT_LAST_NAME_FIELD,
  DEFAULT_LEGAL_LAST_NAME_FIELD,
  DEFAULT_LINE_1_FIELD,
  DEFAULT_LINE_2_FIELD,
  DEFAULT_COUNTRY_FIELD,
  DEFAULT_CITY_FIELD,
  DEFAULT_ZIP_CODE_FIELD,
  DEFAULT_PHONE_FIELD,
  DEFAULT_STATE_FIELD,
  DEFAULT_PROVINCE_FIELD,
  DEFAULT_STATE_PROVINCE_REGION_FIELD,
  DEFAULT_POSTAL_CODE_FIELD,
  DEFAULT_INTL_PHONE_FIELD,
  DEFAULT_LINE_3_FIELD,
} from './fields';

const AE_REQUIREMENTS: AddressField[] = [
  DEFAULT_FIRST_NAME_FIELD,
  DEFAULT_LAST_NAME_FIELD,
  DEFAULT_COUNTRY_FIELD,
  { ...DEFAULT_LINE_1_FIELD, label: 'Street Name' },
  {
    ...DEFAULT_LINE_2_FIELD,
    label: 'Building, floor, apt., or Aramax Account',
    labelExtension: '(Optional)',
  },
  {
    ...DEFAULT_CITY_FIELD,
    label: 'Emirate/City',
    labelExtension: '(E.g. Dubai)',
  },
  {
    ...DEFAULT_STATE_FIELD,
    label: 'Area/District',
    labelExtension: '(E.g. Jumeirah)',
  },
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+97112345678',
  },
];

const EU_REQUIREMENTS: AddressField[] = [
  DEFAULT_FIRST_NAME_FIELD,
  DEFAULT_LAST_NAME_FIELD,
  DEFAULT_COUNTRY_FIELD,
  DEFAULT_LINE_1_FIELD,
  DEFAULT_LINE_2_FIELD,
  DEFAULT_CITY_FIELD,
  DEFAULT_STATE_PROVINCE_REGION_FIELD,
  DEFAULT_POSTAL_CODE_FIELD,
  DEFAULT_INTL_PHONE_FIELD,
];

const UK_REQUIREMENTS: AddressField[] = [
  DEFAULT_FIRST_NAME_FIELD,
  DEFAULT_LAST_NAME_FIELD,
  DEFAULT_COUNTRY_FIELD,
  DEFAULT_LINE_1_FIELD,
  DEFAULT_LINE_2_FIELD,
  DEFAULT_CITY_FIELD,
  DEFAULT_STATE_PROVINCE_REGION_FIELD,
  DEFAULT_POSTAL_CODE_FIELD,
  { ...DEFAULT_INTL_PHONE_FIELD, example: '+447911123456' },
];

const SA_IDENTIFICATION_NUMBER: AddressField = {
  type: 'identification_number',
  label: 'Saudi Arabia ID Number',
  required: true,
  minLength: 10,
  maxLength: 10,
  pattern: /^[0-9]*$/,
  customErrorKey: 'sa_id_field',
};

const KR_PERSONAL_CUSTOMS_CODE: AddressField = {
  type: 'identification_number',
  label: 'Personal Customs Code',
  required: true,
  minLength: 13,
  maxLength: 13,
  pattern: /^P\d{12}$/i,
  example: 'P123456789012',
};

const BH_KW_REQUIREMENTS: AddressField[] = [
  DEFAULT_FIRST_NAME_FIELD,
  DEFAULT_LAST_NAME_FIELD,
  DEFAULT_LINE_1_FIELD,
  {
    ...DEFAULT_LINE_2_FIELD,
    label: 'Building Name, Apt, Suite, etc. (Optional)',
  },
  DEFAULT_CITY_FIELD,
  { ...DEFAULT_STATE_FIELD, label: 'Area Name', required: false },
  DEFAULT_POSTAL_CODE_FIELD,
  DEFAULT_COUNTRY_FIELD,
];

const BH_REQUIREMENTS: AddressField[] = [
  ...BH_KW_REQUIREMENTS,
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+97312345678',
  },
];

const JP_REQUIREMENTS: AddressField[] = [
  DEFAULT_FIRST_NAME_FIELD,
  DEFAULT_LAST_NAME_FIELD,
  DEFAULT_LINE_1_FIELD,
  DEFAULT_LINE_2_FIELD,
  DEFAULT_CITY_FIELD,
  { ...DEFAULT_STATE_FIELD, label: 'Prefecture', maxLength: 3 },
  DEFAULT_POSTAL_CODE_FIELD,
  DEFAULT_COUNTRY_FIELD,
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+8100012345678',
  },
];

const KW_REQUIREMENTS: AddressField[] = [
  ...BH_KW_REQUIREMENTS,
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+96512345678',
  },
];

const KR_REQUIREMENTS: AddressField[] = [
  DEFAULT_FIRST_NAME_FIELD,
  DEFAULT_LAST_NAME_FIELD,
  DEFAULT_LINE_1_FIELD,
  DEFAULT_LINE_2_FIELD,
  DEFAULT_CITY_FIELD,
  { ...DEFAULT_STATE_FIELD, label: 'State or Province' },
  DEFAULT_ZIP_CODE_FIELD,
  DEFAULT_COUNTRY_FIELD,
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+820012345678',
  },
  KR_PERSONAL_CUSTOMS_CODE,
];

const HK_REQUIREMENTS: AddressField[] = [
  DEFAULT_FIRST_NAME_FIELD,
  DEFAULT_LAST_NAME_FIELD,
  DEFAULT_LINE_1_FIELD,
  DEFAULT_LINE_2_FIELD,
  DEFAULT_CITY_FIELD,
  { ...DEFAULT_STATE_FIELD, label: 'District' },
  DEFAULT_COUNTRY_FIELD,
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+85212345678',
  },
];

const IL_REQUIREMENTS: AddressField[] = [
  DEFAULT_FIRST_NAME_FIELD,
  DEFAULT_LAST_NAME_FIELD,
  DEFAULT_LINE_1_FIELD,
  DEFAULT_LINE_2_FIELD,
  { ...DEFAULT_CITY_FIELD, label: 'Locality' },
  { ...DEFAULT_STATE_FIELD, label: 'Area Name' },
  DEFAULT_POSTAL_CODE_FIELD,
  DEFAULT_COUNTRY_FIELD,
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+972123456789',
  },
];

const TW_REQUIREMENTS: AddressField[] = [
  DEFAULT_LEGAL_FIRST_NAME_FIELD,
  DEFAULT_LEGAL_LAST_NAME_FIELD,
  DEFAULT_LINE_1_FIELD,
  DEFAULT_LINE_2_FIELD,
  DEFAULT_CITY_FIELD,
  { ...DEFAULT_STATE_FIELD, label: 'District' },
  DEFAULT_ZIP_CODE_FIELD,
  DEFAULT_COUNTRY_FIELD,
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+8860912345678',
  },
];

const IN_REQUIREMENTS: AddressField[] = [
  DEFAULT_LEGAL_FIRST_NAME_FIELD,
  DEFAULT_LEGAL_LAST_NAME_FIELD,
  DEFAULT_LINE_1_FIELD,
  DEFAULT_LINE_2_FIELD,
  DEFAULT_CITY_FIELD,
  DEFAULT_STATE_FIELD,
  {
    // PIN: Postal Index Number. India Zip Code
    ...DEFAULT_ZIP_CODE_FIELD,
    label: 'PIN Code',
    minLength: 6,
    maxLength: 6,
    pattern: /^[0-9]*$/,
  },
  DEFAULT_COUNTRY_FIELD,
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+911234567890',
  },
];

const MX_REQUIREMENTS: AddressField[] = [
  DEFAULT_LEGAL_FIRST_NAME_FIELD,
  DEFAULT_LEGAL_LAST_NAME_FIELD,
  { ...DEFAULT_LINE_1_FIELD, label: 'Street and house number' },
  DEFAULT_LINE_2_FIELD,
  {
    ...DEFAULT_POSTAL_CODE_FIELD,
    minLength: 5,
    maxLength: 5,
    pattern: /^[0-9]*$/,
  },
  DEFAULT_CITY_FIELD,
  DEFAULT_STATE_FIELD,
  DEFAULT_COUNTRY_FIELD,
  {
    ...DEFAULT_PHONE_FIELD,
    example: '+521234567890',
  },
];

/* eslint-disable id-length */
/**
 * Provides shipping address requirements for each country.
 * This drives address entry forms and validations.
 * Any new labels must be added to the addressForm.json file to get translated to other languages
 */
export const ADDRESS_REQUIREMENTS_BY_COUNTRY: Record<
  string,
  AddressField[] | undefined
> = {
  AE: AE_REQUIREMENTS,
  AT: EU_REQUIREMENTS,
  AU: [
    DEFAULT_FIRST_NAME_FIELD,
    DEFAULT_LAST_NAME_FIELD,
    DEFAULT_LINE_1_FIELD,
    DEFAULT_LINE_2_FIELD,
    DEFAULT_STATE_FIELD,
    DEFAULT_ZIP_CODE_FIELD,
    DEFAULT_CITY_FIELD,
    DEFAULT_COUNTRY_FIELD,
    { ...DEFAULT_INTL_PHONE_FIELD, example: '+61123456789' },
  ],
  AX: EU_REQUIREMENTS,
  BE: EU_REQUIREMENTS,
  BG: EU_REQUIREMENTS,
  BH: BH_REQUIREMENTS,
  CA: [
    DEFAULT_FIRST_NAME_FIELD,
    DEFAULT_LAST_NAME_FIELD,
    DEFAULT_LINE_1_FIELD,
    DEFAULT_LINE_2_FIELD,
    DEFAULT_CITY_FIELD,
    DEFAULT_PROVINCE_FIELD,
    { ...DEFAULT_ZIP_CODE_FIELD, minLength: 6, maxLength: 7 },
    DEFAULT_COUNTRY_FIELD,
    DEFAULT_PHONE_FIELD,
  ],
  CH: EU_REQUIREMENTS,
  CY: EU_REQUIREMENTS,
  CZ: EU_REQUIREMENTS,
  DE: EU_REQUIREMENTS,
  DK: EU_REQUIREMENTS,
  EE: EU_REQUIREMENTS,
  ES: EU_REQUIREMENTS,
  FI: EU_REQUIREMENTS,
  FO: EU_REQUIREMENTS,
  FR: EU_REQUIREMENTS,
  GB: UK_REQUIREMENTS,
  GG: UK_REQUIREMENTS,
  GL: EU_REQUIREMENTS,
  GR: EU_REQUIREMENTS,
  HK: HK_REQUIREMENTS,
  HR: EU_REQUIREMENTS,
  HU: EU_REQUIREMENTS,
  IE: EU_REQUIREMENTS,
  IL: IL_REQUIREMENTS,
  IM: [
    DEFAULT_FIRST_NAME_FIELD,
    DEFAULT_LAST_NAME_FIELD,
    DEFAULT_COUNTRY_FIELD,
    DEFAULT_LINE_1_FIELD,
    DEFAULT_LINE_2_FIELD,
    DEFAULT_CITY_FIELD,
    DEFAULT_POSTAL_CODE_FIELD,
    { ...DEFAULT_INTL_PHONE_FIELD, example: '+447911123456' },
  ],
  IN: IN_REQUIREMENTS,
  IT: EU_REQUIREMENTS,
  IS: EU_REQUIREMENTS,
  JE: UK_REQUIREMENTS,
  JP: JP_REQUIREMENTS,
  KR: KR_REQUIREMENTS,
  KW: KW_REQUIREMENTS,
  LT: EU_REQUIREMENTS,
  LU: EU_REQUIREMENTS,
  LV: EU_REQUIREMENTS,
  MC: EU_REQUIREMENTS,
  MT: EU_REQUIREMENTS,
  MX: MX_REQUIREMENTS,
  NL: EU_REQUIREMENTS,
  NO: EU_REQUIREMENTS,
  NZ: [
    DEFAULT_FIRST_NAME_FIELD,
    DEFAULT_LAST_NAME_FIELD,
    DEFAULT_LINE_1_FIELD,
    DEFAULT_LINE_2_FIELD,
    DEFAULT_CITY_FIELD,
    DEFAULT_PROVINCE_FIELD,
    { ...DEFAULT_ZIP_CODE_FIELD, minLength: 4, maxLength: 4 },
    DEFAULT_COUNTRY_FIELD,
    { ...DEFAULT_INTL_PHONE_FIELD, example: '+64123456789' },
  ],
  PL: EU_REQUIREMENTS,
  PT: EU_REQUIREMENTS,
  QA: [
    DEFAULT_FIRST_NAME_FIELD,
    DEFAULT_LAST_NAME_FIELD,
    DEFAULT_COUNTRY_FIELD,
    {
      ...DEFAULT_LINE_1_FIELD,
      label: 'Zone',
      labelExtension: '(E.g. Zone 38)',
    },
    {
      ...DEFAULT_LINE_2_FIELD,
      label: 'Building Name',
      maxLength: 20,
      labelExtension: '(Optional)',
    },
    {
      ...DEFAULT_LINE_3_FIELD,
      label: 'Street Number',
      maxLength: 20,
      required: true,
    },
    { ...DEFAULT_CITY_FIELD, labelExtension: '(E.g. Doha)' },
    {
      ...DEFAULT_STATE_FIELD,
      label: 'Area',
      labelExtension: '(E.g. Al Sadd)',
    },
    {
      ...DEFAULT_PHONE_FIELD,
      example: '+97412345678',
    },
  ],
  RO: EU_REQUIREMENTS,
  SA: [
    DEFAULT_LEGAL_FIRST_NAME_FIELD,
    DEFAULT_LEGAL_LAST_NAME_FIELD,
    { ...DEFAULT_LINE_1_FIELD, label: 'Street Name and Number' },
    { ...DEFAULT_LINE_2_FIELD, label: 'Neighborhood or District (Optional)' },
    { ...DEFAULT_CITY_FIELD, labelExtension: '(E.g. Riyadh)' },
    {
      ...DEFAULT_POSTAL_CODE_FIELD,
      minLength: 5,
      labelExtension: '(E.g. 14514)',
    },
    DEFAULT_COUNTRY_FIELD,
    {
      ...DEFAULT_PHONE_FIELD,
      example: '+96612345678',
    },
    SA_IDENTIFICATION_NUMBER,
  ],
  SE: EU_REQUIREMENTS,
  SG: [
    DEFAULT_FIRST_NAME_FIELD,
    DEFAULT_LAST_NAME_FIELD,
    DEFAULT_LINE_1_FIELD,
    DEFAULT_LINE_2_FIELD,
    DEFAULT_CITY_FIELD,
    { ...DEFAULT_POSTAL_CODE_FIELD, minLength: 6, maxLength: 6 },
    DEFAULT_COUNTRY_FIELD,
    {
      ...DEFAULT_PHONE_FIELD,
      example: '+6512345678',
    },
  ],
  SK: EU_REQUIREMENTS,
  SI: EU_REQUIREMENTS,
  TW: TW_REQUIREMENTS,
  US: [
    DEFAULT_FIRST_NAME_FIELD,
    DEFAULT_LAST_NAME_FIELD,
    DEFAULT_LINE_1_FIELD,
    DEFAULT_LINE_2_FIELD,
    DEFAULT_CITY_FIELD,
    DEFAULT_STATE_FIELD,
    { ...DEFAULT_ZIP_CODE_FIELD, minLength: 5 },
    DEFAULT_COUNTRY_FIELD,
    DEFAULT_PHONE_FIELD,
  ],
  ZA: [
    DEFAULT_FIRST_NAME_FIELD,
    DEFAULT_LAST_NAME_FIELD,
    DEFAULT_LINE_1_FIELD,
    DEFAULT_LINE_2_FIELD,
    DEFAULT_CITY_FIELD,
    DEFAULT_PROVINCE_FIELD,
    { ...DEFAULT_POSTAL_CODE_FIELD, minLength: 4, maxLength: 4 },
    DEFAULT_COUNTRY_FIELD,
    {
      ...DEFAULT_PHONE_FIELD,
      example: '+270001234567',
    },
  ],
};
/* eslint-enable id-length */
