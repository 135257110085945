'use client';

import { default as i18next } from 'i18next';
import { useEffect, useState } from 'react';
import {
  initReactI18next,
  useTranslation as useTranslationOrg,
} from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { ALL_LANGUAGES, type Language } from '../types/internationalization';
import { getOptions } from './settings';

export { Trans } from 'react-i18next';

const runsOnServerSide = typeof window === 'undefined';

i18next
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./locales/${language}/${namespace}.json`),
    ),
  )
  .init({
    ...getOptions(),
    ns: ['checkout', 'engraving', 'orderPage', 'whoopYourWay', 'retryPage'],
    lng: runsOnServerSide
      ? undefined
      : document.querySelector('html')?.getAttribute('lang') ?? undefined,
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
    preload: runsOnServerSide ? ALL_LANGUAGES : [],
    nsSeparator: ':',
  });

export const i18n = i18next;

// for use on the client
export const useTranslation = (ns: string, lng?: Language, options = {}) => {
  const lang = runsOnServerSide
    ? lng
    : document.querySelector('html')?.getAttribute('lang');
  const ret = useTranslationOrg(ns, options);
  const { i18n } = ret;
  if (runsOnServerSide && lang && i18n.resolvedLanguage !== lang) {
    i18n.changeLanguage(lang);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (activeLng === i18n.resolvedLanguage) return;
      setActiveLng(i18n.resolvedLanguage);
    }, [activeLng, i18n.resolvedLanguage]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!lang || i18n.resolvedLanguage === lang) return;
      i18n.changeLanguage(lang);
    }, [lang, i18n]);
  }
  return ret;
};
