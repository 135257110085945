import type { AnalyticsContext } from 'whoop-analytics';
import {
  isAccessory,
  type AnyProduct,
  type CartProduct,
  type MembershipProduct,
  isEngraving,
  isWhoopYourWay,
} from 'ui';
import type { SkipMemVariantOptions } from '../constants/experiments';

export const getAnalyticsAmount = (amountInCents: number): number => {
  return Number((amountInCents / 100).toFixed(2));
};

export const getItemProperties = (
  product: AnyProduct,
  page?: string,
): AnalyticsContext => {
  if (product.product_type === 'device') {
    return {
      sku: product.id,
      name: 'WHOOP 4.0',
      variant: 'Onyx',
      price: getAnalyticsAmount(product.display_price),
    };
  }
  let family_size,
    membership_quantity,
    name = product.cart_information.name;
  const membership = product as MembershipProduct;
  if (membership.membership_type === 'family') {
    family_size = membership.family_size || 1;
    membership_quantity = 1;
    name = 'Family';
  } else if (
    membership.membership_type === 'prepaid' &&
    membership.trial_months === 12
  ) {
    name = 'Annual';
  }
  return {
    sku: product.id,
    name,
    price: getAnalyticsAmount(product.display_price),
    page,
    family_size,
    membership_quantity,
  };
};

export const getMembershipProperties = (
  product: MembershipProduct,
): AnalyticsContext => {
  return {
    sku: product.id,
    name: product.title,
    price: getAnalyticsAmount(product.display_price),
    membership: product.trial_months * 30,
    is_trial: product.membership_type === 'trial',
  };
};

export const getAccessoryContext = (cartProducts: CartProduct[]) => {
  const accessories = cartProducts.filter(
    (product) =>
      isAccessory(product.item) ||
      isEngraving(product.item) ||
      isWhoopYourWay(product.item),
  );
  const accessoryCount = accessories.reduce(
    (acc, curr) => acc + curr.quantity,
    0,
  );
  const uniqueAccessories = accessories.reduce((acc, _) => acc + 1, 0);

  const accessoryTotal = accessories.reduce(
    (acc, curr) => acc + curr.item.display_price * curr.quantity,
    0,
  );

  return { accessories, accessoryCount, accessoryTotal, uniqueAccessories };
};

// Get the skip_mem_select property to use in the Amplitude event
export const getSkipMemSelectProperty = (
  membershipParam?: string,
  skipMemVariant?: SkipMemVariantOptions,
  sizeParam?: string,
) => {
  let skip_mem_select, family_size;
  if (membershipParam === 'trial') {
    skip_mem_select = 'trial';
  } else if (membershipParam === 'family' && sizeParam) {
    skip_mem_select = 'family';
    family_size = sizeParam;
  } else if (
    membershipParam &&
    skipMemVariant &&
    skipMemVariant !== 'control'
  ) {
    skip_mem_select = membershipParam;
  }

  return { skip_mem_select, family_size };
};
