import type { DeviceProduct, DeviceType, MembershipProduct } from '../types';

export const getDeviceProductForCart = (
  devices: DeviceProduct[],
  membership: MembershipProduct,
): DeviceProduct => {
  const defaultDevice = devices.find(
    (device) => device.device_type === 'default',
  );
  const preownedDevice = devices.find(
    (device) => device.device_type === 'preowned',
  );

  if (membership.membership_type === 'trial' && preownedDevice) {
    return preownedDevice;
  } else if (defaultDevice) {
    return defaultDevice;
  }
  throw new Error('No valid devices exist');
};

export const getDeviceByType = (
  devices: DeviceProduct[],
  type: DeviceType,
): DeviceProduct => {
  const device = devices.find((device) => device.device_type === type);

  if (!device) {
    throw new Error(`No devices of type "${type}" exist`);
  }

  return device;
};

// If the SKU is for a device, return the default device
export const getDefaultDeviceProductFromSku = (
  sku: string,
  devices: DeviceProduct[],
): DeviceProduct | undefined => {
  const device = devices.find((device) => device.id === sku);
  if (device && device.device_type !== 'default') {
    return devices.find((device) => device.device_type === 'default');
  }
  return device;
};
