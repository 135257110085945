'use client';

import type { ReactNode } from 'react';
import { createContext, useContext, useRef } from 'react';
import type { StoreApi } from 'zustand';
import type {
  JFCurrency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import type {
  AccessoryGroup,
  JoinFlowContent,
  JoinProducts,
} from 'services/generated/growth-content-service';
import type { CheckoutTotal } from 'services/generated/order-service';
import type { PromoCodeState, PromoInfo, WhoopYourWayConfigurations } from 'ui';
import { useStore } from 'zustand';
import type { CountryCode } from '@whoop/i18n/types/address';
import { createSiteStore, DEFAULT_PROPS } from '@/store/siteStore';
import type { ISiteStore } from '@/store/siteStore';

export const SiteStoreContext = createContext<StoreApi<ISiteStore> | null>(
  null,
);

export interface SiteStoreProviderProps {
  children: ReactNode;
  language: Language;
  region: Region;
  country: CountryCode;
  currency: JFCurrency;
  accessories?: AccessoryGroup[];
  initialTotals?: CheckoutTotal[];
  promoCode?: PromoCodeState;
  promoContent?: JoinFlowContent;
  promoInfo?: PromoInfo;
  requiredProducts?: JoinProducts;
  whoopYourWayConfigs?: WhoopYourWayConfigurations;
}

export const SiteStoreProvider = ({
  children,
  ...props
}: SiteStoreProviderProps): JSX.Element => {
  const storeRef = useRef<StoreApi<ISiteStore>>();
  if (!storeRef.current) {
    storeRef.current = createSiteStore({ ...DEFAULT_PROPS, ...props });
  }

  return (
    <SiteStoreContext.Provider value={storeRef.current}>
      {children}
    </SiteStoreContext.Provider>
  );
};

export const useSiteStore = <T,>(selector: (store: ISiteStore) => T): T => {
  const siteStoreContext = useContext(SiteStoreContext);
  if (!siteStoreContext) {
    throw new Error('useSiteStore must be used within SiteStoreProvider');
  }

  return useStore(siteStoreContext, selector);
};
