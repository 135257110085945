import type { Currency } from '../types/internationalization';
import type { TaxInformation, TaxType } from '../types/tax';
import { getRegionFromRequest, isRestOfWorld } from './i18nHelpers';

/* eslint-disable id-length */
const taxInformationByCountry: Record<string, TaxInformation | undefined> = {
  AE: {
    type: 'vat',
    taxID: '100450470800003',
  },
  AU: {
    type: 'gst',
    taxID: '48 130 545 003',
  },
  BH: {
    type: 'vat',
    taxID: '5073413',
  },
  CH: {
    type: 'vat',
    taxID: 'CHE-241.362.648 IVA',
  },
  GB: {
    type: 'vat',
    taxID: 'GB322664807',
  },
  IS: {
    type: 'vat',
    taxID: '144310',
  },
  JE: {
    type: 'vat',
    taxID: '0123213',
  },
  NL: {
    type: 'vat',
    taxID: 'NL8256.92.295.B01',
  },
  NO: {
    type: 'vat',
    taxID: '2058170',
  },
  NZ: {
    type: 'gst',
    taxID: '130452531',
  },
  SA: {
    type: 'vat',
    taxID: '311859102700003',
  },
  UK: {
    type: 'vat',
    taxID: 'GB322664807',
  },
};
/* eslint-enable id-length */

/**
 * Provides tax type and digital & physical tax IDs for countries where we have tax registration requirements
 * @param country The country we need tax information for
 * @returns TaxInformation: tax type (vat, gst, etc.), tax ID for digital purchases, and tax ID for physical purchases
 */
export const getTaxInformationForCountry = (
  country: string,
): TaxInformation | undefined => {
  const region = getRegionFromRequest(country);
  const taxInfo = taxInformationByCountry[country];
  if (taxInfo) return taxInfo;
  if (region === 'EU') return taxInformationByCountry.NL;
};

export const getTaxTypeForCurrency = (currency: Currency): TaxType => {
  const taxTypes: Record<Currency, TaxType> = {
    aed: 'vat',
    aud: 'gst',
    cad: 'gst',
    eur: 'vat',
    gbp: 'vat',
    nzd: 'gst',
    sar: 'vat',
    usd: 'tax',
    inr: 'gst',
    qar: 'gst',
  };
  return taxTypes[currency];
};

/**
 * Returns whether to show messaging that additional taxes may be applied
 * @param country
 * @param currency
 */
export const canApplyAdditionalTaxes = (
  country: string,
  currency?: Currency,
): boolean => {
  const region = getRegionFromRequest(country);

  return (
    (region &&
      currency &&
      isRestOfWorld(currency, region) &&
      region !== 'HK' &&
      region !== 'IN') ||
    country === 'AX' ||
    country === 'FO'
  );
};

/**
 * Returns whether country should show "Your price has been updated to reflect the VAT of your region"
 * @param country
 */
export const canPriceUpdateToIncludeVat = (country: string): boolean => {
  return ['GG', 'JE', 'QA'].includes(country);
};

/**
 * @deprecated – Do not use in new development! This calculation should now be done on the backend!
 */
export const getVatInclusivePrice = (
  price: number,
  currency: Currency,
): number => {
  const flatTaxRates: Record<string, number> = {
    aed: 0.05,
    aud: 0.1,
    gbp: 0.2,
    nzd: 0.15,
    sar: 0.15,
  };

  const flatTaxRate = flatTaxRates[currency];
  if (!flatTaxRate) return price;

  // If display price is within 1 cent, truncate the cent (AU annual membership price specifically)
  const vatInclusivePrice = Math.round(price * (1 + flatTaxRate));
  if ((vatInclusivePrice - 1) % 10 === 0) return vatInclusivePrice - 1;
  if ((vatInclusivePrice + 1) % 10 === 0) return vatInclusivePrice + 1;
  return vatInclusivePrice;
};

/**
 * @deprecated – Do not use in new development! This determination should now come fromthe backend
 */
export const isDisplayedVatInclusive = (currency: Currency): boolean => {
  return ['aed', 'aud', 'eur', 'gbp', 'nzd', 'sar'].includes(currency);
};
