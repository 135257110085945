// Order or billing currecy
export const ALL_CURRENCIES = [
  'usd',
  'aed',
  'aud',
  'cad',
  'eur',
  'gbp',
  'nzd',
  'sar',
  'inr',
  'qar',
] as const;
export type Currency = (typeof ALL_CURRENCIES)[number]; // creates union type like 'usd' | 'aed' | ...
export type JFCurrency = Exclude<Currency, 'inr' | 'qar'>;
export const DEFAULT_CURRENCY: Currency = 'usd';

// Site language
export const ALL_LANGUAGES = ['en', 'de', 'fr', 'es', 'it', 'pt'] as const; //languages added here are used for supportedLngs in i18n init
export type Language = (typeof ALL_LANGUAGES)[number]; // creates union type like 'en' | 'de' | ...
export const DEFAULT_LANGUAGE: Language = 'en';

// Country or group of countries that can be shipped to (may be deprecated)
export const ALL_REGIONS = [
  'US',
  'AE',
  'AU',
  'CA',
  'EU',
  'NZ',
  'SA',
  'UK',
  'IN',

  // "Rest of World" - countries outside of US paying in USD
  'BH', // Bahrain
  'HK', // Hong Kong
  'IL', // Israel
  'JP', // Japan
  'KR', // South Korea
  'KW', // Kuwait
  'SG', // Singapore
  'TW', // Taiwan
  'ZA', // South Africa
  'MX', // Mexico
];
export type Region = (typeof ALL_REGIONS)[number]; // creates union type like 'US' | 'AE' | ...
export const DEFAULT_REGION: Region = 'US';

// Billing region / checkout_origin in the memberships table
export const ALL_BILLING_REGIONS = [
  'domestic', // in US / paying in USD
  'australia',
  'ca', // in Canada / paying in CAD
  'international', // in EU / paying in  EUR
  'nz',
  'sa', // in Saudi Arabia / paying in Riyal
  'uae',
  'uk',
  'in', // in India / paying in INR
  'qa', // in Qatar / paying in QAR
] as const;
export type BillingRegion = (typeof ALL_BILLING_REGIONS)[number]; // creates union type like 'domestic' | 'australia' | ...
export const DEFAULT_BILLING_REGION: BillingRegion = 'domestic';

export const DEFAULT_LOCALE = `${DEFAULT_LANGUAGE}-${DEFAULT_REGION}`;

export const RegionCurrencyDetails: {
  region: Region;
  displayName: string;
  currency: string;
}[] = [
  { region: 'US', displayName: 'US', currency: '$USD' },
  { region: 'AU', displayName: 'AU', currency: '$AUD' },
  { region: 'CA', displayName: 'CA', currency: '$CAD' },
  { region: 'EU', displayName: 'EU', currency: '€EUR' },
  { region: 'UK', displayName: 'UK', currency: '£GBP' },
  { region: 'NZ', displayName: 'NZ', currency: '$NZD' },
  { region: 'AE', displayName: 'AE/QA', currency: 'AED' },
  { region: 'SA', displayName: 'SA', currency: 'SAR' },
  { region: 'BH', displayName: 'BH', currency: '$USD' },
  { region: 'HK', displayName: 'HK', currency: '$USD' },
  { region: 'IL', displayName: 'IL', currency: '$USD' },
  { region: 'IN', displayName: 'IN', currency: '$USD' },
  { region: 'JP', displayName: 'JP', currency: '$USD' },
  { region: 'KR', displayName: 'KR', currency: '$USD' },
  { region: 'KW', displayName: 'KW', currency: '$USD' },
  { region: 'MX', displayName: 'MX', currency: '$USD' },
  { region: 'SG', displayName: 'SG', currency: '$USD' },
  { region: 'TW', displayName: 'TW', currency: '$USD' },
  { region: 'ZA', displayName: 'ZA', currency: '$USD' },
];
