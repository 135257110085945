export interface Province {
  code: string;
  name: string;
}

/* eslint-disable id-length */
/**
 * Provinces/States mapped to country
 */
export const PROVINCES_BY_COUNTRY: Record<string, Province[] | undefined> = {
  AU: [
    { code: 'NSW', name: 'New South Wales' },
    { code: 'QLD', name: 'Queensland' },
    { code: 'SA', name: 'South Australia' },
    { code: 'TAS', name: 'Tasmania' },
    { code: 'VIC', name: 'Victoria' },
    { code: 'WA', name: 'Western Australia' },
    { code: 'ACT', name: 'Australian Capital Territory' },
    { code: 'NT', name: 'Northern Territory' },
  ],
  CA: [
    { code: 'AB', name: 'Alberta' },
    { code: 'BC', name: 'British Columbia' },
    { code: 'MB', name: 'Manitoba' },
    { code: 'NB', name: 'New Brunswick' },
    { code: 'NL', name: 'Newfoundland and Labrador' },
    { code: 'NS', name: 'Nova Scotia' },
    { code: 'NU', name: 'Nunavut' },
    { code: 'NT', name: 'Northwest Territories' },
    { code: 'ON', name: 'Ontario' },
    { code: 'PE', name: 'Prince Edward Island' },
    { code: 'QC', name: 'Quebec' },
    { code: 'SK', name: 'Saskatchewan' },
    { code: 'YT', name: 'Yukon' },
  ],
  IN: [
    { code: 'AN', name: 'Andaman and Nicobar Islands' },
    { code: 'AP', name: 'Andhra Pradesh' },
    { code: 'AR', name: 'Arunachal Pradesh' },
    { code: 'AS', name: 'Assam' },
    { code: 'BR', name: 'Bihar' },
    { code: 'CH', name: 'Chandigarh' },
    { code: 'CT', name: 'Chhattisgarh' },
    { code: 'DN', name: 'Dadra and Nagar Haveli' },
    { code: 'DD', name: 'Daman and Diu' },
    { code: 'DL', name: 'Delhi' },
    { code: 'GA', name: 'Goa' },
    { code: 'GJ', name: 'Gujarat' },
    { code: 'HR', name: 'Haryana' },
    { code: 'HP', name: 'Himachal Pradesh' },
    { code: 'JK', name: 'Jammu and Kashmir' },
    { code: 'JH', name: 'Jharkhand' },
    { code: 'KA', name: 'Karnataka' },
    { code: 'KL', name: 'Kerala' },
    { code: 'LA', name: 'Ladakh' },
    { code: 'LD', name: 'Lakshadweep' },
    { code: 'MP', name: 'Madhya Pradesh' },
    { code: 'MH', name: 'Maharashtra' },
    { code: 'MN', name: 'Manipur' },
    { code: 'ML', name: 'Meghalaya' },
    { code: 'MZ', name: 'Mizoram' },
    { code: 'NL', name: 'Nagaland' },
    { code: 'OR', name: 'Odisha' },
    { code: 'PY', name: 'Puducherry' },
    { code: 'PB', name: 'Punjab' },
    { code: 'RJ', name: 'Rajasthan' },
    { code: 'SK', name: 'Sikkim' },
    { code: 'TN', name: 'Tamil Nadu' },
    { code: 'TG', name: 'Telangana' },
    { code: 'TR', name: 'Tripura' },
    { code: 'UP', name: 'Uttar Pradesh' },
    { code: 'UT', name: 'Uttarakhand' },
    { code: 'WB', name: 'West Bengal' },
  ],
  JP: [
    { code: '23', name: 'Aichi' },
    { code: '05', name: 'Akita' },
    { code: '02', name: 'Aomori' },
    { code: '12', name: 'Chiba' },
    { code: '38', name: 'Ehime' },
    { code: '18', name: 'Fukui' },
    { code: '40', name: 'Fukuoka' },
    { code: '07', name: 'Fukushima' },
    { code: '21', name: 'Gifu' },
    { code: '10', name: 'Gunma' },
    { code: '34', name: 'Hiroshima' },
    { code: '01', name: 'Hokkaido' },
    { code: '28', name: 'Hyōgo' },
    { code: '08', name: 'Ibaraki' },
    { code: '17', name: 'Ishikawa' },
    { code: '03', name: 'Iwate' },
    { code: '37', name: 'Kagawa' },
    { code: '46', name: 'Kagoshima' },
    { code: '14', name: 'Kanagawa' },
    { code: '39', name: 'Kōchi' },
    { code: '43', name: 'Kumamoto' },
    { code: '26', name: 'Kyōto' },
    { code: '24', name: 'Mie' },
    { code: '04', name: 'Miyagi' },
    { code: '45', name: 'Miyazaki' },
    { code: '20', name: 'Nagano' },
    { code: '42', name: 'Nagasaki' },
    { code: '29', name: 'Nara' },
    { code: '15', name: 'Niigata' },
    { code: '44', name: 'Ōita' },
    { code: '33', name: 'Okayama' },
    { code: '47', name: 'Okinawa' },
    { code: '27', name: 'Ōsaka' },
    { code: '41', name: 'Saga' },
    { code: '11', name: 'Saitama' },
    { code: '25', name: 'Shiga' },
    { code: '32', name: 'Shimane' },
    { code: '22', name: 'Shizuoka' },
    { code: '09', name: 'Tochigi' },
    { code: '36', name: 'Tokushima' },
    { code: '13', name: 'Tōkyō' },
    { code: '31', name: 'Tottori' },
    { code: '16', name: 'Toyama' },
    { code: '30', name: 'Wakayama' },
    { code: '06', name: 'Yamagata' },
    { code: '35', name: 'Yamaguchi' },
    { code: '19', name: 'Yamanashi' },
  ],
  KR: [
    { code: '26', name: 'Busan' },
    { code: '27', name: 'Daegu' },
    { code: '30', name: 'Daejeon' },
    { code: '42', name: 'Gangwon' },
    { code: '29', name: 'Gwangju City' },
    { code: '41', name: 'Gyeonggi' },
    { code: '28', name: 'Incheon' },
    { code: '49', name: 'Jeju' },
    { code: '43', name: 'North Chungcheong' },
    { code: '47', name: 'North Gyeongsang' },
    { code: '45', name: 'North Jeolla' },
    { code: '50', name: 'Sejong' },
    { code: '11', name: 'Seoul' },
    { code: '44', name: 'South Chungcheong' },
    { code: '48', name: 'South Gyeongsang' },
    { code: '46', name: 'South Jeolla' },
    { code: '31', name: 'Ulsan' },
  ],
  MX: [
    { code: 'AGU', name: 'Aguascalientes' },
    { code: 'BCN', name: 'Baja California' },
    { code: 'BCS', name: 'Baja California Sur' },
    { code: 'CAM', name: 'Campeche' },
    { code: 'CHP', name: 'Chiapas' },
    { code: 'CHH', name: 'Chihuahua' },
    { code: 'COA', name: 'Coahuila' },
    { code: 'COL', name: 'Colima' },
    { code: 'CMX', name: 'Ciudad de México' },
    { code: 'DUR', name: 'Durango' },
    { code: 'GUA', name: 'Guanajuato' },
    { code: 'GRO', name: 'Guerrero' },
    { code: 'HID', name: 'Hidalgo' },
    { code: 'JAL', name: 'Jalisco' },
    { code: 'MEX', name: 'México' },
    { code: 'MIC', name: 'Michoacán' },
    { code: 'MOR', name: 'Morelos' },
    { code: 'NAY', name: 'Nayarit' },
    { code: 'NLE', name: 'Nuevo León' },
    { code: 'OAX', name: 'Oaxaca' },
    { code: 'PUE', name: 'Puebla' },
    { code: 'QUE', name: 'Querétaro' },
    { code: 'ROO', name: 'Quintana Roo' },
    { code: 'SLP', name: 'San Luis Potosí' },
    { code: 'SIN', name: 'Sinaloa' },
    { code: 'SON', name: 'Sonora' },
    { code: 'TAB', name: 'Tabasco' },
    { code: 'TAM', name: 'Tamaulipas' },
    { code: 'TLA', name: 'Tlaxcala' },
    { code: 'VER', name: 'Veracruz' },
    { code: 'YUC', name: 'Yucatán' },
    { code: 'ZAC', name: 'Zacatecas' },
  ],
  NZ: [
    { code: 'AUK', name: 'Auckland' },
    { code: 'BOP', name: 'Bay of Plenty' },
    { code: 'CAN', name: 'Canterbury' },
    { code: 'GIS', name: 'Gisborne' },
    { code: 'HKB', name: "Hawke's Bay" },
    { code: 'MWT', name: 'Manawatu-Wanganui' },
    { code: 'MBH', name: 'Marlborough' },
    { code: 'NSN', name: 'Nelson' },
    { code: 'NTL', name: 'Northland' },
    { code: 'OTA', name: 'Otago' },
    { code: 'STL', name: 'Southland' },
    { code: 'TKI', name: 'Taranaki' },
    { code: 'TAS', name: 'Tasman' },
    { code: 'WKO', name: 'Waikato' },
    { code: 'WGN', name: 'Wellington' },
    { code: 'WTC', name: 'West Coast' },
  ],
  US: [
    { code: 'AL', name: 'Alabama' },
    { code: 'AK', name: 'Alaska' },
    { code: 'AZ', name: 'Arizona' },
    { code: 'AR', name: 'Arkansas' },
    { code: 'CA', name: 'California' },
    { code: 'CO', name: 'Colorado' },
    { code: 'CT', name: 'Connecticut' },
    { code: 'DE', name: 'Delaware' },
    { code: 'DC', name: 'District of Columbia' },
    { code: 'FL', name: 'Florida' },
    { code: 'GA', name: 'Georgia' },
    { code: 'GU', name: 'Guam' },
    { code: 'HI', name: 'Hawaii' },
    { code: 'ID', name: 'Idaho' },
    { code: 'IL', name: 'Illinois' },
    { code: 'IN', name: 'Indiana' },
    { code: 'IA', name: 'Iowa' },
    { code: 'KS', name: 'Kansas' },
    { code: 'KY', name: 'Kentucky' },
    { code: 'LA', name: 'Louisiana' },
    { code: 'ME', name: 'Maine' },
    { code: 'MD', name: 'Maryland' },
    { code: 'MA', name: 'Massachusetts' },
    { code: 'MI', name: 'Michigan' },
    { code: 'MN', name: 'Minnesota' },
    { code: 'MS', name: 'Mississippi' },
    { code: 'MO', name: 'Missouri' },
    { code: 'MT', name: 'Montana' },
    { code: 'NE', name: 'Nebraska' },
    { code: 'NV', name: 'Nevada' },
    { code: 'NH', name: 'New Hampshire' },
    { code: 'NJ', name: 'New Jersey' },
    { code: 'NM', name: 'New Mexico' },
    { code: 'NY', name: 'New York' },
    { code: 'NC', name: 'North Carolina' },
    { code: 'ND', name: 'North Dakota' },
    { code: 'OH', name: 'Ohio' },
    { code: 'OK', name: 'Oklahoma' },
    { code: 'OR', name: 'Oregon' },
    { code: 'PA', name: 'Pennsylvania' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'RI', name: 'Rhode Island' },
    { code: 'SC', name: 'South Carolina' },
    { code: 'SD', name: 'South Dakota' },
    { code: 'TN', name: 'Tennessee' },
    { code: 'TX', name: 'Texas' },
    { code: 'UT', name: 'Utah' },
    { code: 'VT', name: 'Vermont' },
    { code: 'VA', name: 'Virginia' },
    { code: 'WA', name: 'Washington' },
    { code: 'WV', name: 'West Virginia' },
    { code: 'WI', name: 'Wisconsin' },
    { code: 'WY', name: 'Wyoming' },
    { code: 'AA', name: 'Armed Americas' },
    { code: 'AE', name: 'Armed Europe' },
    { code: 'AP', name: 'Armed Pacific' },
  ],
  ZA: [
    { code: 'EC', name: 'Eastern Cape' },
    { code: 'FS', name: 'Free State' },
    { code: 'GP', name: 'Gauteng' },
    { code: 'KZN', name: 'KwaZulu-Natal' },
    { code: 'LP', name: 'Limpopo' },
    { code: 'MP', name: 'Mpumalanga' },
    { code: 'NW', name: 'North West' },
    { code: 'NC', name: 'Northern Cape' },
    { code: 'WC', name: 'Western Cape' },
  ],
};
/* eslint-enable id-length */
