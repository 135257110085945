import type { AddressFieldType } from '../../types/address';
import type { Region } from '../../types/internationalization';

/** This entire file can be deleted once web-components no longer pulls any of these in */

/**
 * @deprecated Use ADDRESS_REQUIREMENTS_BY_COUNTRY instead
 */
export const ADDRESS_FIELD_REQUIRED: Record<string, boolean> = {
  firstName: true,
  lastName: true,
  line1: true,
  line2: false,
  city: true,
  province: true,
  postalCode: true,
  phone: true,
};

const getAddressLineMaxLength = (region: Region): number => {
  switch (region) {
    case 'UK':
      return 30;
    case 'EU':
      return 40;
    default:
      return 200;
  }
};

const getProvinceLineMaxLength = (region: Region): number => {
  switch (region) {
    case 'UK':
      return 30;
    case 'EU':
      return 40;
    default:
      return 50;
  }
};

/**
 * @deprecated Use ADDRESS_REQUIREMENTS_BY_COUNTRY instead
 */
export const ADDRESS_FIELD_MAX_LENGTH = {
  firstName: () => 40,
  lastName: () => 80,
  line1: (region: Region) => getAddressLineMaxLength(region),
  line2: (region: Region) => getAddressLineMaxLength(region),
  city: () => 50,
  province: (region: Region) => getProvinceLineMaxLength(region),
  postalCode: () => 50,
  phone: () => 40,
};

/**
 * @deprecated Use ADDRESS_REQUIREMENTS_BY_COUNTRY instead
 */
export const EU_MAX_FIELD_LENGTH = 40;

/**
 * @deprecated Use ADDRESS_REQUIREMENTS_BY_COUNTRY instead
 */
export const UK_MAX_FIELD_LENGTH = 30;

/**
 * @deprecated Use ADDRESS_REQUIREMENTS_BY_COUNTRY instead
 */
export const ADDRESS_FIELD_MIN_LENGTH: Record<string, number> = {
  postal_code: 3,
  phone: 8,
};

const ALPHANUMERIC_FIELDS = ['city', 'province'];
const ONLY_LETTERS_FIELDS = ['first_name', 'last_name'];

/**
 * @deprecated Use AddressFieldErrorType instead
 */
export enum AddressFieldError {
  REQUIRED_ERROR = 'requiredError',
  LENGTH_ERROR = 'lengthError',
  INVALID_PHONE = 'invalidPhoneError',
  INVALID_PHONE_AND_COUNTRY_CODE = 'errors.valid-phone-country-code-req',
  LETTERS_ERROR = 'lettersError',
  NUMBERS_ERROR = 'numbersError',
  NUMBERS_AND_SPECIAL_CHARACTERS_ERROR = 'numbersAndSpecialCharactersError',
  SPECIAL_CHARACTERS_ERROR = 'specialCharactersError',
  INVALID_POSTAL_CODE = 'invalidPostalCodeError',
}

/**
 * @deprecated Use AddressFieldValidationResponse instead
 */
export interface AddressFieldValidation {
  isValid: boolean;
  error?: string;
}

/**
 * @deprecated
 */
export interface AddressFieldInfo {
  value: string;
  field: AddressFieldType;
  region: Region;
  errorCallback?: () => string;
}

const onlyLettersPattern = /^[a-zA-Z][a-zA-Z\s]*$/;
const lettersAndNumbersPatternStrict =
  /^([a-zA-Z]+|[a-zA-Z\s\d]*[a-zA-Z]+[a-zA-Z\s\d]*)$/;
const numbersAndSpecialCharactersPattern = /^([^\w\s]|\d)+$/;
const specialCharactersPattern = /^[^\w\s]+$/;
const containsLettersPattern = /.*[a-zA-Z].*/;
const containsLettersOrNumbersPattern = /.*[a-zA-Z0-9].*/;

/**
 * @deprecated Use ADDRESS_REQUIREMENTS_BY_COUNTRY instead
 */
export const getPhoneNumberPattern = (region: Region) => {
  if (region === 'AE') return /^\+97[1,4](\d*)$/;
  if (['US', 'CA'].includes(region)) return /^(\+)?(\d*)$/;
  return /^\+(\d*)$/;
};

const isShippingPhoneNumberValid = (phone: string, region: Region) => {
  const phoneNumberPattern = getPhoneNumberPattern(region);
  return phoneNumberPattern.test(phone);
};

/**
 * @deprecated Use ADDRESS_REQUIREMENTS_BY_COUNTRY instead
 */
export const getPhoneNumberError = (region: Region): string => {
  return ['US', 'CA'].includes(region)
    ? AddressFieldError.INVALID_PHONE
    : AddressFieldError.INVALID_PHONE_AND_COUNTRY_CODE;
};

/**
 * @deprecated Use react-hook-form for form validation where possible. Otherwise use validateAddressInput instead
 */
export const validateAddressField = ({
  value,
  field,
  region,
  errorCallback,
}: AddressFieldInfo): AddressFieldValidation => {
  if (ADDRESS_FIELD_REQUIRED[field] && (!value || value.length === 0)) {
    return { isValid: false, error: AddressFieldError.REQUIRED_ERROR };
  } else if (!ADDRESS_FIELD_REQUIRED[field] && (!value || value.length === 0)) {
    return { isValid: true };
  }

  if (
    Boolean(value) &&
    value.length > ADDRESS_FIELD_MAX_LENGTH[field](region)
  ) {
    return { isValid: false, error: AddressFieldError.LENGTH_ERROR };
  }

  if (ONLY_LETTERS_FIELDS.includes(field)) {
    if (!onlyLettersPattern.exec(value)) {
      return { isValid: false, error: AddressFieldError.LETTERS_ERROR };
    }
  }

  if (ALPHANUMERIC_FIELDS.includes(field)) {
    if (!lettersAndNumbersPatternStrict.exec(value)) {
      return { isValid: false, error: AddressFieldError.NUMBERS_ERROR };
    }
  }

  if (field === 'line1') {
    if (numbersAndSpecialCharactersPattern.exec(value)) {
      return {
        isValid: false,
        error: AddressFieldError.NUMBERS_AND_SPECIAL_CHARACTERS_ERROR,
      };
    }
  }

  if (field === 'line2') {
    if (specialCharactersPattern.exec(value)) {
      return {
        isValid: false,
        error: AddressFieldError.SPECIAL_CHARACTERS_ERROR,
      };
    }
  }

  if (field === 'phone') {
    if (
      value.length < ADDRESS_FIELD_MIN_LENGTH.phone ||
      !isShippingPhoneNumberValid(value, region)
    ) {
      return {
        isValid: false,
        error: errorCallback ? errorCallback() : getPhoneNumberError(region),
      };
    }
  }

  if (field === 'postal_code') {
    if (value.length < ADDRESS_FIELD_MIN_LENGTH.postal_code) {
      return { isValid: false, error: AddressFieldError.INVALID_POSTAL_CODE };
    }
  }

  return { isValid: true };
};

interface Requirements {
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: RegExp;
}

/**
 * @deprecated Use getAddressRequirementsForCountry instead
 */
export const addressValidationRequirements = ({
  field,
  region,
}: {
  field: AddressFieldType;
  region: Region;
}): Requirements => {
  const requirements: Requirements = {
    maxLength: ADDRESS_FIELD_MAX_LENGTH[field](region),
    minLength: ADDRESS_FIELD_MIN_LENGTH[field],
    required: ADDRESS_FIELD_REQUIRED[field],
  };

  if (ONLY_LETTERS_FIELDS.includes(field)) {
    requirements.pattern = onlyLettersPattern;
  } else if (ALPHANUMERIC_FIELDS.includes(field)) {
    requirements.pattern = lettersAndNumbersPatternStrict;
  } else if (field === 'line1') {
    requirements.pattern = containsLettersPattern;
  } else if (field === 'line2') {
    requirements.pattern = containsLettersOrNumbersPattern;
  } else if (field === 'phone') {
    requirements.pattern = getPhoneNumberPattern(region);
    requirements.minLength = ADDRESS_FIELD_MIN_LENGTH.phone;
  }

  return requirements;
};
