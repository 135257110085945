import {
  DEFAULT_LANGUAGE,
  type Language,
} from '@whoop/i18n/types/internationalization';

export const BASE_WHOOP_URL = 'https://whoop.com';
export const PRIVACY_POLICY_URL = `${BASE_WHOOP_URL}/privacy`;
export const TERMS_OF_SALE_URL = `${BASE_WHOOP_URL}/termsofsale`;
export const TERMS_OF_USE_URL = `${BASE_WHOOP_URL}/termsofuse`;
export const BASE_SUPPORT_URL = 'https://support.whoop.com';

export const APP_WHOOP_GIFT_EXTENSIONS_URL =
  'https://app.whoop.com/gifts/extensions';

export const getSupportLocalizedUrl = (
  language: Language = DEFAULT_LANGUAGE,
) => {
  return `https://${getSupportModifier(language)}support.whoop.com/`;
};

const getSupportModifier = (language: Language) => {
  if (isDefaultLanguage(language)) return '';
  return `${language}.`;
};

const isDefaultLanguage = (language: Language): boolean => {
  return language === DEFAULT_LANGUAGE;
};

export type AnalyticsPageTitle =
  | 'Initial'
  | 'Join'
  | 'Order'
  | 'Accessories'
  | 'Membership'
  | 'Retry'
  | 'receipt'
  | 'Family';
export const getCurrentPageFromURL = (): AnalyticsPageTitle => {
  if (typeof window !== 'undefined') {
    const { pathname } = window.location;

    if (pathname.includes('/order')) {
      return 'Order';
    } else if (pathname.includes('/accessories')) {
      return 'Accessories';
    } else if (pathname.includes('/membership')) {
      return 'Membership';
    } else if (pathname.includes('/retry')) {
      return 'Retry';
    } else if (pathname.includes('/receipt')) {
      return 'receipt';
    } else if (pathname.includes('/family')) {
      return 'Family';
    }
    return 'Join';
  }
  throw new Error('window is undefined');
};
